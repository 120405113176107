import { createSlice } from "@reduxjs/toolkit";

const attendanceReducer = createSlice({
    name: 'attendance',
    initialState: {
        attendances: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getAttendancesStart: (state) => {
            state.isFetching = true;
        },
        getAttendancesSuccess: (state, action) => {
            state.isFetching = false;
            state.attendances = action.payload;
        },
        getAttendancesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addAttendanceStart: (state) => {
            state.isFetching = true;
        },

        addAttendanceSuccess: (state, action) => {
            state.isFetching = false;
            const newAttendance = action.payload;
            state.attendances = [newAttendance, ...state.attendances];
        },

        addAttendanceFailure: (state) => {
            state.error = true;
        },

        // Update user actions
        updateAttendanceStart: (state) => {
            state.isFetching = true;
        },

        updateAttendanceSuccess: (state, action) => {
            state.isFetching = false;
            const attendancesIndex = state.attendances?.findIndex(attendances => attendances?._id === action.payload._id);
            if (attendancesIndex !== -1) {
                state.attendances[attendancesIndex] = action.payload;
            }
        },

        updateAttendanceFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getAttendancesStart, getAttendancesSuccess, getAttendancesFailure, addAttendanceFailure, addAttendanceStart, addAttendanceSuccess, updateAttendanceStart, updateAttendanceSuccess, updateAttendanceFailure } = attendanceReducer.actions;
export default attendanceReducer.reducer;

