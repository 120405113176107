import { Card, Col, Container, Row } from "react-bootstrap";
import "./MeetingCard.css";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import CommonButton from "../../button/CommonButton";
import { Link } from "react-router-dom";
import { isMeetingLive } from "../../../config";
import { MdDateRange } from "react-icons/md";
import { IoTimeOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useState } from "react";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import { useDispatch } from "react-redux";

const MeetingCard = ({ meetings }) => {
  const setBgColorOfElements = (status, elementType) => {
    const lowerCaseStatus = status.toLowerCase();
    const colorMap = {
      upcoming: {
        titleWrapper: "var(--violet)",
        meetingDateTimeBg: "var(--LViolet)",
        mdtBorder: "var(--violet)",
        meetingCBtn: "var(--violet)",
        meetingDTText: "var(--violet)",
      },

      today: {
        titleWrapper: "var(--Green)",
        meetingDateTimeBg: "var(--lGreen)",
        mdtBorder: "var(--Green)",
        meetingCBtn: "var(--Green)",
        meetingDTText: "var(--Green)",
      },
      // Default colors if the status is unknown
      default: {
        titleWrapper: "var(--Grey)",
        meetingDateTimeBg: "var(--Grey)",
        mdtBorder: "var(--Grey)",
        meetingCBtn: "var(--Grey)",
        meetingDTText: "var(--white)",
      },
    };
    return (
      colorMap[lowerCaseStatus]?.[elementType] || colorMap.default[elementType]
    );
  };

  const getStatusBgColor = (status) => {
    switch (status) {
      case "upcoming":
        return "var(--violet)";

      case "today":
        return "var(--Green)";
    }
  };

  const [isPopupActive, setIsPopupActive] = useState(false);
  const [deletingEmployeeId, setDeletingEmployeeId] = useState(null);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isItConfimationPopup, setIsItConfimationPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const handleDropDownClick = (id) => {
    setActiveDropdown((prevState) => (prevState === id ? null : id));
  };

  const handleConfirmPopup = (popupStatus, id) => {
    setIsPopupActive(popupStatus);
    setIsItConfimationPopup(true);
    setDeletingEmployeeId(id);
  };

  return (
    <div className="meeting-card-wrapper custom-padding">
      <Container>
        <Row>
          {meetings?.map((meeting, index) => {
            const titleBgColor = setBgColorOfElements(
              meeting.status,
              "titleWrapper"
            );

            const mdtBg = setBgColorOfElements(
              meeting.status,
              "meetingDateTimeBg"
            );

            const mdtBorder = setBgColorOfElements(meeting.status, "mdtBorder");

            const umBtn = setBgColorOfElements(meeting.status, "meetingCBtn");

            const mdtText = setBgColorOfElements(
              meeting.status,
              "meetingDTText"
            );
            return (
              <Col lg={4} className="mb-4">
                {/* Card */}
                <Card className="meeting-card">
                  {/* title block  */}
                  <div
                    className="mc-title-options-wrapper position-relative"
                    style={{
                      backgroundColor: titleBgColor,
                    }}
                  >
                    <div className="mc-title">
                      <h5 className="m-0">{meeting.status}</h5>
                    </div>
                    {/* <div className="mc-options">
                      <PiDotsThreeOutlineVerticalFill />
                    </div> */}
                    {
                      currentUser?._id === meeting?.scheduledBy && (
                        <>
                          <BsThreeDotsVertical
                            onClick={() => handleDropDownClick(meeting?._id)}
                            className="text-white clickable"
                          />
                          {activeDropdown === meeting?._id && (
                            <div className="dropdown-optio-wrapper">
                              <ul>
                                {/* {(currentUser?.isUserDepartmentHead || currentUser?.isUserOrganisationOwner) && (
                              <>
                                <Link to={`/edit-client/${meeting?._id}`}>
                                  <li>Edit</li>
                                </Link>
                                <Link
                                  onClick={() =>
                                    handleConfirmPopup(true, meeting._id)
                                  }
                                >
                                  <li>Delete</li>
                                </Link>
                              </>
                            )} */}
                                <Link to={`/edit-meeting/${meeting?._id}`}>
                                  <li>Edit</li>
                                </Link>
                                <Link>
                                  <li>Delete</li>
                                </Link>
                              </ul>
                            </div>
                          )}
                        </>
                      )
                    }
                  </div>

                  {/* meeting block  */}
                  <div className="meeting-details-wrapper">
                    <div className="meeting">
                      {/* about meeting  */}
                      <div className="mc-client-name text-center">
                        {/* <h6>Client: {meeting.clientName}</h6> */}
                        <h6>Agenda</h6>
                        <p>{meeting?.agenda}</p>
                      </div>

                      {/* Project name  */}
                      {/* <div className="mc-project-name">
                        <h6>Project: {meeting.projectName}</h6>
                      </div> */}

                      {/* meeting date time  */}
                      <div
                        className="meeting-date-time text-center mb-2 mx-auto"
                        style={{
                          backgroundColor: mdtBg,
                          border: "unset",
                        }}
                      >
                        <MdDateRange style={{ color: mdtText }} />
                        <p className="m-0" style={{ color: mdtText }}>
                          {meeting.meetingDate}
                        </p>
                      </div>
                      <div
                        className="meeting-date-time text-center mx-auto"
                        style={{
                          backgroundColor: mdtBg,
                          border: "unset",
                        }}
                      >
                        <IoTimeOutline style={{ color: mdtText }} />
                        <p className="m-0" style={{ color: mdtText }}>
                          {meeting.startTime} - {meeting.endTime}
                        </p>
                      </div>

                      {/* button to join or view meeting agenda */}
                      <div className="meeting-catd-btn">
                        {
                          meeting.status === "upcoming" && (
                            <button
                              className="mc-btn disabled"
                              style={{
                                backgroundColor: getStatusBgColor(meeting?.status),
                              }}
                              disabled
                            >
                              Join Now
                            </button>
                          )
                        }

                        {
                          meeting.status === "today" && isMeetingLive(meeting?.startTime, meeting?.endTime) && (
                            <a href={meeting.link}>
                              <button
                                className={"mc-btn"}
                                style={{
                                  backgroundColor: getStatusBgColor(meeting?.status),
                                }}
                              // disabled = {isMeetingLive(meeting?.startTime, meeting?.endTime) ? true : false}
                              >
                                Join Now
                              </button>
                            </a>
                          )
                        }

                        {
                          meeting.status === "today" && !isMeetingLive(meeting?.startTime, meeting?.endTime) && (
                            <button
                              className={"mc-btn disbaled"}
                              style={{
                                backgroundColor: getStatusBgColor(meeting?.status),
                              }}
                              disabled
                            >
                              Join Now
                            </button>
                          )
                        }
                      </div>

                      {/* cancel or not attending btn  */}
                      <div className="cancel-na-meeting-btns-wrapper">
                        <div className="cancel-btn">
                          <Link to="#">Cancel</Link>
                        </div>

                        <div className="not-attending-btn">
                          <Link to="#">Not Attending</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default MeetingCard;
