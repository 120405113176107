import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../header/Header.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonButton from "../button/CommonButton";
import { userRequest } from "../../requestMethod";
import useCurrentUser from "../../custom-hooks/useCurrentUser";
import Popup from "../popup/Popup";
import MiniLoader from "../miniLoader/MiniLoader";
import { addAttendanceFailure, addAttendanceStart, addAttendanceSuccess, updateAttendanceFailure, updateAttendanceStart, updateAttendanceSuccess } from "../../redux/attendanceRedux";
import { useDispatch } from "react-redux";

const Header = () => {

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const [isWorkStart, setIsWorkStart] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isItConfimationPopup, setIsItConfimationPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupText, setPopupText] = useState("");

  useEffect(() => {
    const checkWorkStatus = async () => {
      try {
        const res = await userRequest.get('/attendance/status');
        if (res.status === 200) {
          setIsWorkStart(res.data.isWorkStart);
        }
      } catch (error) {
      }
    };
    checkWorkStatus();
  }, [currentUser]);


  const handleStartWorkButton = async () => {
    setIsButtonClicked(true)
    setIsPopupActive(false);
    setIsLoaderActive(true);
    dispatch(addAttendanceStart());
    try {
      const res = await userRequest.post('/attendance/create');
      if (res?.status === 200) {
        dispatch(addAttendanceSuccess(res.data));
        setIsWorkStart(true);
        setIsItConfimationPopup(false);
        setIsLoaderActive(false);
        setPopupStatus(false);
      }
    } catch (error) {
      dispatch(addAttendanceFailure());
      setIsItConfimationPopup(false)
      setIsLoaderActive(false)
      setPopupStatus(false)
      setIsPopupActive(true)
      setPopupText(error.response.data ? error.response.data : error.message)
    }
  }

  const handleLogOffWorkButton = async () => {
    try {
      setIsPopupActive(false);
      setIsLoaderActive(true)
      dispatch(updateAttendanceStart());
      const res = await userRequest.put('/attendance/logoff');
      if (res?.status === 200) {
        // setIsPopupActive(false);
        dispatch(updateAttendanceSuccess(res?.data?.data));
        setIsLoaderActive(false);
        setIsItConfimationPopup(false);
        setIsPopupActive(true);
        setIsWorkStart(false);
        setPopupStatus(true);
        setPopupText(res?.data?.message);

      }
    } catch (error) {
      dispatch(updateAttendanceFailure());
      setIsItConfimationPopup(false)
      setIsLoaderActive(false)
      setPopupStatus(false)
      setIsPopupActive(true)
      setPopupText(error.message)
    }
  }

  const hidePopup = () => {
    setIsPopupActive(false);
    setIsLoaderActive(false);
  };

  // const handleDelete = () => {
  //   deleteClient(deletingEmployeeId, dispatch);
  // };

  const handleConfirmPopup = (popupStatus) => {
    setIsPopupActive(popupStatus);
    setIsItConfimationPopup(true);
    // setDeletingEmployeeId(id);
  };

  return (
    <>
      <section className="main-header">
        <Container>
          <div className="header-wrapper">
            <div className="header-logo">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo/uniworld-logo.png`}
                alt="logo"
              />
            </div>

            <div className="nav-wrapper">
              {
                currentUser && (
                  <div className="help">
                    {/* <Link to={"/"}>Help</Link> */}
                    {
                      isWorkStart ? (
                        <CommonButton
                          btnName={"Log Off"}
                          btnClass="common-btn btn-edit"
                          onClickAction={() => handleConfirmPopup(true)}
                        />
                      ) : (
                        <CommonButton
                          btnName={"Start Work"}
                          btnClass="common-btn btn-edit"
                          onClickAction={handleStartWorkButton}
                        // isDisable={isButtonClicked}
                        />
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        </Container>
      </section>
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup
          status={popupStatus}
          message={popupText}
          isItConfimationPopup={isItConfimationPopup}
          handleDelete={handleLogOffWorkButton}
          hidePopup={hidePopup}
          buttonName="Log Off"
        />
      )}
    </>
  );
};

export default Header;
