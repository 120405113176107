import { Col, Container, Row } from "react-bootstrap";
import "./AccountSetting.css";
import HeaderNav from "../../sidebar/HeaderNav";
import AnnouncementBar from "../../../../common-components/announcement-bar/AnnouncementBar";
import EmployeeProfile from "../employee-profile/EmployeeProfile";
import useCurrentUser from "../../../../custom-hooks/useCurrentUser";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";

const AccountSetting = () => {
  const currentUser = useCurrentUser();

  const [allEmpDetails, setAllEmpDetails] = useState([]);

  useEffect(() => {
    setAllEmpDetails([currentUser]);
  }, [currentUser]);

  // const allEmpDetails = [
  //   {
  //     profilePicture: "user-img.png",
  //     firstName: "manan",
  //     lastName: "kapoor",
  //     team: "web & IT",
  //     designation: "UI/UX designer",
  //     gender: "male",
  //     dob: "9 june, 2002",
  //     email: "manan@gmail.com",
  //     phone: "9876543210",
  //     address: "123, xyz street, xyz city",
  //     city: "xyz",
  //     state: "xyz",
  //     country: "xyz",
  //     pincode: "123456",
  //   },
  // ];

  return (
    <section className="account-setting-wrapper">
      <Container fluid>
        <Row>
          <Col lg={12} className="p-0">
            {/* breadcrumb  */}
            <Breadcrumb mainTitle="Account Setting" />

            {/* employee profile  */}
            <EmployeeProfile empDetails={currentUser} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AccountSetting;
