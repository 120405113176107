import { createSlice } from "@reduxjs/toolkit";

const roleReducer = createSlice({
    name: 'role',
    initialState: {
        roles: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get user actions
        getRolesStart: (state) => {
            state.isFetching = true;
        },
        getRolesSuccess: (state, action) => {
            state.isFetching = false;
            state.roles = action.payload;
        },
        getRolesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add admin actions 
        addRoleStart: (state) => {
            state.isFetching = true;
        },

        addRoleSuccess: (state, action) => {
            state.isFetching = false;
            const newRole = action.payload;
            state.roles = [...state.roles, newRole];
        },

        addRoleFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Delete user actions
        deleteRoleStart: (state) => {
            state.isFetching = true;
        },

        deleteRoleSuccess: (state, action) => {
            state.isFetching = false;
            state.roles.splice(
                state.roles.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteRoleFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // deleteRoleTaskSuccess: (state, action) => {
        //     state.isFetching = false;
        //     const { roleId, taskIndex } = action.payload;

        //     // Find the index of the role
        //     const roleIndex = state.roles.findIndex(role => role._id === roleId);
        //     if (roleIndex !== -1) {
        //         // Check if taskIndex is valid
        //         if (taskIndex >= 0 && taskIndex < state.roles[roleIndex].tasks.length) {
        //             // Remove the task from the tasks array
        //             state.roles[roleIndex].tasks.splice(taskIndex, 1);
        //         }
        //     }
        // },

        // Update user actions
        updateRoleStart: (state) => {
            state.isFetching = true;
        },

        // updateRoleSuccess: (state, action) => {
        //     state.isFetching = false;
        //     const roleIndex = state.roles.findIndex(role => role._id === action.payload._id);
        //     if (roleIndex !== -1) {
        //         state.roles[roleIndex] = action.payload;
        //     }
        // },

        updateRoleSuccess: (state, action) => {
            state.isFetching = false;
            const roleIndex = state.roles.findIndex(role => role._id === action.payload.role._id);
            if (roleIndex !== -1) {
                // Update immutably
                state.roles = [
                    ...state.roles.slice(0, roleIndex),
                    action.payload.role,
                    ...state.roles.slice(roleIndex + 1),
                ];
            }
        },
        

        updateRoleFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { getRolesStart, getRolesSuccess, getRolesFailure, deleteRoleStart, deleteRoleSuccess, deleteRoleFailure, addRoleFailure, addRoleStart, addRoleSuccess, updateRoleStart, updateRoleSuccess, updateRoleFailure } = roleReducer.actions;
export default roleReducer.reducer;

