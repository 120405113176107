import { Card, Col, Container, ProgressBar, Row } from "react-bootstrap";
import "./Project.css";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { useState } from "react";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import { useDispatch } from "react-redux";
import CommonButton from "../../button/CommonButton";
import { getStatus, hasPermission } from "../../../config";
import MiniLoader from "../../miniLoader/MiniLoader";
import Popup from "../../popup/Popup";
import { deleteProjectFailure, deleteProjectStart, deleteProjectSuccess } from "../../../redux/projectRedux";
import { userRequest } from "../../../requestMethod";

const Project = ({ projects }) => {
  const getPriorityBgColor = (priority) => {
    switch (priority) {
      case "high":
        return "var(--LRed)";

      case "medium":
        return "var(--lGreen)";

      case "low":
        return "var(--LOrange)";
    }
  };

  const getPriorityTxtColor = (priority) => {
    switch (priority) {
      case "high":
        return "var(--Red)";

      case "medium":
        return "var(--Green)";

      case "low":
        return "var(--Orange)";
    }
  };

  const setBgColorOfElements = (status, elementType) => {
    const lowerCaseStatus = status?.toLowerCase();
    const colorMap = {
      delayed: {
        titleWrapper: "var(--Red)",
        cardWrapper: "var(--LRed)",
        borderColor: "var(--Red)",
        progressBarBg: "var(--Red)",
        priorityBg: "var(--LRed)",
        priorityBorder: "var(--Red)",
      },
      "in progress": {
        titleWrapper: "var(--Orange)",
        cardWrapper: "var(--LOrange)",
        borderColor: "var(--Orange)",
        progressBarBg: "var(--Orange)",
        priorityBg: "var(--LOrange)",
        priorityBorder: "var(--Orange)",
      },
      completed: {
        titleWrapper: "var(--Green)",
        cardWrapper: "var(--lGreen)",
        borderColor: "var(--Green)",
        progressBarBg: "var(--Green)",
        priorityBg: "var(--lGreen)",
        priorityBorder: "var(--Green)",
      },
      suspended: {
        titleWrapper: "var(--RBlue)",
        cardWrapper: "var(--lRBlue)",
        borderColor: "var(--lRBlue)",
        progressBarBg: "var(--lRBlue)",
        priorityBg: "var(--lRBlue)",
        priorityBorder: "var(--lRBlue)",
      },
      "inactive": {
        titleWrapper: "var(--Grey)",
        cardWrapper: "var(--LGrey)",
        borderColor: "var(--LGrey)",
        progressBarBg: "var(--LGrey)",
        priorityBg: "var(--LGrey)",
        priorityBorder: "var(--LGrey)",
      },
      paused: {
        titleWrapper: "var(--DAqua)",
        cardWrapper: "var(--lDAqua)",
        borderColor: "var(--lDAqua)",
        progressBarBg: "var(--lDAqua)",
        priorityBg: "var(--lDAqua)",
        priorityBorder: "var(--lDAqua)",
      },

      // Default colors if the status is unknown
      default: {
        titleWrapper: "var(--black)",
        cardWrapper: "var(--lBlack)",
        borderColor: "var(--lBlack)",
        progressBarBg: "var(--lBlack)",
        priorityBg: "var(--lBlack)",
        priorityBorder: "var(--lBlack)",
      },
    };

    return (
      colorMap[lowerCaseStatus]?.[elementType] || colorMap.default[elementType]
    );
  };

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const [isPopupActive, setIsPopupActive] = useState(false);
  const [deletingEmployeeId, setDeletingEmployeeId] = useState(null);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isItConfimationPopup, setIsItConfimationPopup] = useState(false);
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);


  const hnadleDropDownClick = (id) => {
    setActiveDropdown((prevState) => (prevState === id ? null : id));
  };

  const deleteProject = async (id, dispatch) => {
    setIsLoaderActive(true)
    setIsPopupActive(false)
    setIsItConfimationPopup(false)
    dispatch(deleteProjectStart());
    try {
      const res = await userRequest.delete(`/project/${id}`);
      if (res?.status === 200) {
        dispatch(deleteProjectSuccess(id));
        setIsLoaderActive(false);
        setIsItConfimationPopup(false)
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res.data)
      }
    } catch (error) {
      dispatch(deleteProjectFailure(true));
      setIsLoaderActive(false);
      setIsItConfimationPopup(false)
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.data)
    }
  }

  const hidePopup = () => {
    setIsPopupActive(false);
    setIsLoaderActive(false);
  };

  const handleDelete = () => {
    deleteProject(deletingEmployeeId, dispatch);
  };

  const handleConfirmPopup = (popupStatus, id) => {
    setIsPopupActive(popupStatus);
    setIsItConfimationPopup(true);
    setDeletingEmployeeId(id);
  };

  return (
    <>
      <section className="project-card-wrapper">
        <Container>
          <Row>
            {
              projects?.map((project, index) => {
                const titleBgColor = setBgColorOfElements(
                  project?.status,
                  "titleWrapper"
                );
                const cardBgColor = setBgColorOfElements(
                  project?.status,
                  "cardWrapper"
                );
                const borderColor = setBgColorOfElements(
                  project?.status,
                  "borderColor"
                );
                const progressBarBg = setBgColorOfElements(
                  project?.status,
                  "progressBarBg"
                );
                const priorityBg = setBgColorOfElements(
                  project?.status,
                  "priorityBg"
                );
                const priorityBorder = setBgColorOfElements(
                  project?.status,
                  "priorityBorder"
                );
                return (
                  <Col lg={4}>
                    {/* Card */}
                    <Card className="project-card mb-4">
                      {/* title block  */}
                      <div
                        className="pc-title-options-wrapper"
                        style={{
                          backgroundColor: titleBgColor,
                        }}
                      >
                        <div className="pc-title">
                          <h5 className="m-0">
                            {getStatus("task", project?.status)}
                          </h5>
                        </div>
                        <div className="pc-options position-relative">
                          {/* <PiDotsThreeOutlineVerticalFill /> */}
                          {(hasPermission(currentUser, ["edit_project", "delete_project"])) && (
                            <>
                              <PiDotsThreeOutlineVerticalFill
                                onClick={() => hnadleDropDownClick(project?._id)}
                              />
                              {activeDropdown === project?._id && (
                                <div className="dropdown-optio-wrapper">
                                  <ul>
                                    {/* Show Edit option */}
                                    {hasPermission(currentUser, ["edit_project"]) && (
                                      <Link to={`/edit-project/${project?._id}`}>
                                        <li>Edit</li>
                                      </Link>
                                    )}
                                    {/* Show Delete option */}
                                    {hasPermission(currentUser, ["delete_project"]) && (
                                      <Link
                                        onClick={() => handleConfirmPopup(true, project._id)}
                                      >
                                        <li>Delete</li>
                                      </Link>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {/* projects block  */}
                      <div
                        className="all-projects-wrapper"
                        style={{ backgroundColor: cardBgColor }}
                      >
                        <div className="project">
                          {/* project name  */}
                          <div className="project-name">
                            <h5 className="m-0">{project?.title}</h5>
                          </div>

                          {/* progress bar  */}
                          <div className="project-progress">
                            <ProgressBar
                              now={project?.progress}
                              className="pp-bar"
                              style={{
                                "--bs-progress-bar-bg": progressBarBg,
                                border: `0.5px solid ${borderColor}`,
                              }}
                            />
                            <p className="progress-label m-0">
                              {project?.progress}%
                            </p>
                          </div>

                          {/* start date  */}
                          <div className="delivery-date">
                            <h6>{project?.endDate}</h6>
                          </div>

                          {/* priority & manage project button */}
                          <div className="priority-manage-project-container">
                            {/* priority block  */}
                            <div
                              className="project-priority"
                              style={{
                                backgroundColor: getPriorityBgColor(
                                  project?.priority
                                ),
                                border: "unset",
                              }}
                            >
                              <p
                                className="m-0"
                                style={{
                                  color: getPriorityTxtColor(project?.priority),
                                }}
                              >
                                {project?.priority}
                              </p>
                            </div>

                            {/* manage project button  */}
                            <div className="manage-project-btn">
                              <Link to={`/manage-project/${project?._id}`}>
                                <CommonButton
                                  btnName="Manage Project"
                                  btnClass="common-btn manage-project-btn"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })
            }
          </Row>
        </Container>
      </section>
      {isLoaderActive && <MiniLoader />}
      {
        isPopupActive && (
          <Popup
            status={popupStatus}
            message={popupText}
            isItConfimationPopup={isItConfimationPopup}
            handleDelete={handleDelete}
            hidePopup={hidePopup}
          />
        )
      }
    </>
  );
};

export default Project;
