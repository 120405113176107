import { Container, Row, Col } from "react-bootstrap";
import "./EmployeeProfile.css";
import { MdOutlineModeEdit, MdSave } from "react-icons/md";
import CommonButton from "../../../../common-components/button/CommonButton";
import { useState } from "react";

const EmployeeProfile = ({ empDetails }) => {
  const [editEmployeeDetails, setEditedEmployeeDetails] = useState([
    empDetails,
  ]);

  const [isEditMode, setEditMode] = useState(false);

  const handleInputChange = (index, field, value) => {
    const updatedDetails = [...editEmployeeDetails];
    updatedDetails[index][field] = value; // updating the details

    setEditedEmployeeDetails(updatedDetails);
  };

  const handlePfpChange = (index, event) => {
    if (event.target.files && event.target.files[0]) {
      const newEmpPfp = URL.createObjectURL(event.target.files[0]);
      handleInputChange(index, "profilePicture", newEmpPfp);
    }
  };

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const saveChanges = () => {
    setEditMode(false);
  };
  return (
    <section className="emp-profile-wrapper custom-padding">
      <Container fluid className="p-0">
        {editEmployeeDetails?.map((employeeDetail, index) => {
          return (
            <Row key={index}>
              <Col lg={12}>
                {/* profile pic and change */}
                <div className="profile-pic-container p-rel">
                  <div className="bg-wrapper"></div>
                  <div className="profile-pic-wrapper">
                    <div
                      className={`pfp-change ${
                        isEditMode ? "change-pfp-position" : ""
                      }`}
                    >
                      {/* profile photo */}
                      <div className="profile-pic">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/user-imgs/${employeeDetail.profilePicture}`}
                          alt=""
                        />
                      </div>

                      {isEditMode && (
                        <div className="change-profile-pic">
                          <label
                            htmlFor="profile-pic-input"
                            className="profile-pic-label"
                          >
                            Change Profile Picture
                          </label>
                          <input
                            id="profile-pic-input"
                            type="file"
                            onChange={(e) => handlePfpChange(index, e)}
                            style={{ display: "none" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>

              {/* basic information  */}
              <Col lg={12}>
                <div className="basic-emp-info-card-container">
                  <div className="head-edit-btn">
                    {/* heading */}
                    <div className="basic-info-card-head">
                      <h6 className="m-0">Basic Information</h6>
                    </div>

                    {/* edit & save button  */}
                    <div className="edit-info-btn">
                      {isEditMode ? (
                        <CommonButton
                          btnClass="btn-save"
                          btnName={
                            <>
                              <MdSave />
                              &nbsp;Save
                            </>
                          }
                          onClickAction={saveChanges}
                        />
                      ) : (
                        <CommonButton
                          btnClass="btn-edit"
                          btnName={
                            <>
                              <MdOutlineModeEdit />
                              &nbsp;Edit
                            </>
                          }
                          onClickAction={toggleEditMode}
                        />
                      )}
                    </div>
                  </div>

                  <Row>
                    {/* <Col lg={4} key={field}>
                      <div className="emp-info-wrapper">
                        <div className="emp-info-label">
                          <p>
                            {field.charAt(0).toUpperCase() +
                              field.slice(1).replace(/([A-Z])/g, " $1")}
                          </p>
                        </div>
                        <div className="emp-info">
                          {isEditMode ? (
                            <input
                              type="text"
                              value={employeeDetail[field]}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  field,
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <p>{employeeDetail[field]}</p>
                          )}
                        </div>
                      </div>
                    </Col> */}
                    {[
                      "name",
                      "team",
                      "designation",
                      "gender",
                      "dateOfBirth",
                      "phone",
                      "email",
                      "address",
                      "city",
                      "pincode",
                      "state",
                      "country",
                    ].map((field) => (
                      <Col lg={4} key={field}>
                        <div className="emp-info-wrapper">
                          <div className="emp-info-label">
                            <p>
                              {field.charAt(0).toUpperCase() +
                                field.slice(1).replace(/([A-Z])/g, " $1")}
                            </p>
                          </div>
                          <div className="emp-info">
                            {isEditMode ? (
                              <input
                                type="text"
                                value={employeeDetail[field]}
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    field,
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              <p>{employeeDetail[field]}</p>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </Row>
          );
        })}
      </Container>
    </section>
  );
};

export default EmployeeProfile;
