import { Col, Container, Row } from "react-bootstrap";
import "./OnlineMeeting.css";
import { RiVideoAddLine } from "react-icons/ri";
import { LuPlusSquare } from "react-icons/lu";
import { MdCancelPresentation } from "react-icons/md";
import { Link } from "react-router-dom";

const OnlineMeeting = () => {
  return (
    <div className="online-meeting-wrapper custom-padding">
      <Container>
        <Row>
          <Col lg={4}>
            <Link to={'/schedule-new-meeting'}>
              <div className="meeting-options-wrapper new-meeting text-center">
                <div className="meeting-options new-meeting-icon">
                  <RiVideoAddLine />
                </div>
                <p>New Meeting</p>
              </div>
            </Link>
          </Col>
          <Col lg={4}>
            <div className="meeting-options-wrapper join-meeting text-center">
              <div className="meeting-options join-meeting-icon">
                <LuPlusSquare />
              </div>
              <p>Join Meeting</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="meeting-options-wrapper cancelled-meetings text-center">
              <div className="meeting-options cancelled-meetings-icon">
                <MdCancelPresentation />
              </div>
              <p>Cancelled Meetings</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OnlineMeeting;
