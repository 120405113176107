import {
  clientServices,
  clientStatus,
  departmentName,
  designationName,
  employeeLevel,
  employeeTypes,
  intentOptionForUserCalenderForm,
  leaveType,
  newEmployeeTypes,
  oldEmployeeTypes,
  priority,
  status,
  statusOptionsForUserCalenderForm,
  typeOfPostOptionsForUserCalenderForm,
} from "./config";

export const newEmployeeSchema = [
  {
    name: "name",
    type: "text",
    label: "Name",
    placeholder: "Enter your Name",
    validation: "required",
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Enter your Email",
    validation: "required|email",
  },
  {
    name: "phone",
    type: "text",
    label: "Phone Number",
    placeholder: "Enter your Contact Number",
    validation: "required",
  },
  {
    name: "dateOfBirth",
    type: "date",
    label: "Date of Birth",
    validation: "required",
  },
  {
    name: "department",
    type: "dropdown",
    label: "Select Department",
    options: departmentName,
    validation: "required",
  },
  {
    name: "designation",
    type: "conditionalDropdown",
    label: "Designation",
    options: designationName,
    placeholder: "Enter Employee Designation",
    validation: "required",
  },
  {
    name: "employeeStatus",
    type: "dropdown",
    label: "Select Employee Status",
    options: newEmployeeTypes,
    validation: "required",
  },
  {
    name: "employeeLevel",
    type: "dropdown",
    label: "Select Employee Level",
    options: employeeLevel,
    validation: "required",
  },
];

export const oldEmployeeSchema = [
  {
    name: "name",
    type: "text",
    label: "Name",
    placeholder: "Enter your Name",
    validation: "required",
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Enter your Email",
    validation: "required|email",
  },
  {
    name: "phone",
    type: "text",
    label: "Phone Number",
    placeholder: "Enter your Contact Number",
    validation: "required",
  },
  {
    name: "dateOfBirth",
    type: "date",
    label: "Date of Birth",
    validation: "required",
  },
  {
    name: "department",
    type: "dropdown",
    label: "Select Department",
    options: departmentName,
    validation: "required",
  },
  {
    name: "designation",
    type: "conditionalDropdown",
    label: "Designation",
    options: designationName,
    placeholder: "Enter Employee Designation",
    validation: "required",
  },
  {
    name: "employeeStatus",
    type: "dropdown",
    label: "Select Employee Status",
    options: oldEmployeeTypes,
    validation: "required",
  },
  {
    name: "employeeLevel",
    type: "dropdown",
    label: "Select Employee Level",
    options: employeeLevel,
    validation: "required",
  },
];

export const userCalenderSchema = [
  {
    name: "event",
    type: "text",
    label: "Event detail",
    placeholder: "Enter text here",
    validation: "required",
    fieldWidth: 12
  },
];

export const clientSchema = [
  {
    name: "name",
    type: "text",
    label: "Client Name",
    placeholder: "Enter client name",
    validation: "required",
  },
  {
    name: "brandName",
    type: "text",
    label: "Brand Name",
    placeholder: "Enter brand name",
    validation: "required",
  },
  {
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Enter client email",
    validation: "required|email",
  },
  {
    name: "phone",
    type: "text",
    label: "Phone Number",
    placeholder: "Enter client contact number",
    validation: "required",
  },
  {
    name: "clientPointOfContact",
    type: "text",
    label: "Point of contact",
    placeholder: "Enter person name",
    validation: "required",
  },
  {
    name: "brandLogo",
    type: "file",
    label: "Upload brand logo",
  },
  {
    name: "clientServices",
    type: "dropdown",
    label: "Select client services",
    options: clientServices,
    multiple: true,
    validation: "required",
  },
  {
    name: "clientStatus",
    type: "dropdown",
    label: "Select client status",
    options: clientStatus,
    validation: "required",
  },
  {
    type: "inputFieldsBasedOnSelectedCheckbox",
    label: "POC",
    checkBoxDependencyName: "clientServices",
    checkBoxDependencyLabel: "client services",
    numberOfInputFields: 1,
    inputFields: [
      {
        name: "pocName",
        type: "text",
        label: "Point of Contact Name",
        placeholder: "Enter contact name",
      },
      {
        name: "pocContact",
        type: "text",
        label: "Contact Number",
        placeholder: "Enter contact number",
      },
      {
        name: "pocDepartment",
        type: "text",
        label: "Department",
        placeholder: "Enter department",
      },
    ],
  },
];

export const projectSchema = [
  {
    name: "title",
    type: "text",
    label: "Project Title",
    placeholder: "Enter project title",
    validation: "required",
  },
  {
    name: "client",
    type: "dropdown",
    label: "Client Name",
    // placeholder: "Enter brand name",
    validation: "required",
  },
  {
    name: "startDate",
    type: "date",
    label: "Onboard Date",
    validation: "required|date",
  },
  {
    name: "endDate",
    type: "date",
    label: "Deadline",
    validation: "required|date",
  },
  {
    name: "projectLeads",
    type: "text",
    label: "Project Leads",
    placeholder: "Enter client contact number",
    validation: "required",
  },
  {
    name: "projectPointOfContact",
    type: "text",
    label: "Point of contact",
    placeholder: "Enter person name",
    validation: "required",
  },
  {
    name: "teams",
    type: "dropdown",
    label: "Select team memebers",
    // placeholder: "",
    // validation: "required",
  },
  {
    name: "clientStatus",
    type: "dropdown",
    label: "Select client services",
    options: clientServices,
    multiple: true,
    validation: "required",
  },
  {
    name: "clientStatus",
    type: "dropdown",
    label: "Select client status",
    options: clientStatus,
    validation: "required",
  },
];

// new ticket form schema
export const newTicketSchema = [
  {
    name: "subject",
    type: "text",
    label: "Subject",
    placeholder: "Write here...",
    validation: "required",
  },
  {
    name: "raised by",
    type: "dropdown",
    label: "Raised By",
    // options: raisedBy,
    validation: "required",
  },
  {
    name: "priority",
    type: "dropdown",
    label: "Priority",
    options: priority,
    validation: "required",
  },
  {
    name: "status",
    type: "dropdown",
    label: "Status",
    options: status,
    validation: "required",
  },
];

// leave form schema
export const leaveFormSchema = [
  {
    name: "start date",
    type: "date",
    label: "Start Date",
    validation: "required",
  },
  {
    name: "end date",
    type: "date",
    label: "End Date",
    validation: "required",
  },
  {
    name: "no of days",
    type: "number",
    label: "No of Days",
    validation: "required",
  },
  {
    name: "reason",
    type: "textarea",
    label: "Reason",
    validation: "required",
  },
];

// new task form schema
export const newTaskFormSchema = [
  {
    name: "project",
    type: "text",
    label: "Project",
    validation: "required",
  },
  {
    name: "client name",
    type: "text",
    label: "Client Name",
    validation: "required",
  },
  {
    name: "priority",
    type: "dropdown",
    label: "Priority",
    options: priority,
    validation: "required",
  },
  {
    name: "status",
    type: "dropdown",
    label: "Status",
    options: status,
    validation: "required",
  },
  {
    name: "start date",
    type: "date",
    label: "Start Date",
    validation: "required",
  },
  {
    name: "deadline",
    type: "date",
    label: "Deadline",
    validation: "required",
  },
  {
    name: "assignee",
    type: "dropdown",
    label: "Assignee",
    // options: assignee,
    validation: "required",
  },
  {
    name: "description",
    type: "textarea",
    label: "Description",
    validation: "required",
  },
];

export const notificationSchema = [
  {
    name: "notification",
    type: "textarea",
    label: "Enter text here",
    validation: "required",
  },
];
