import React, { useEffect } from "react";
import DynamicForm from "../../../common-components/form/DynamicForm";
import { employeeSchema, newEmployeeSchema } from "../../../formSchema";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import "./AddEmployee.css";
import { useDispatch, useSelector } from "react-redux";
import { departmentName, designationName, employeeLevel, newEmployeeTypes } from "../../../config";
import { getAllEmployees, getAllRoles } from "../../../redux/apiCalls";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";

const AddEmployee = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    getAllRoles(dispatch)
  }, [dispatch]);

  useEffect(() => {
    getAllEmployees(dispatch)
  }, [dispatch]);



  const { roles, isRolesFetching } = useSelector((state) => state.role);
  const { isFetching : isEmployeesFetching, employees: allEmployees } = useSelector( (state) => state?.employee);

  console.log("state from add employees ==>", roles)

  const newEmployeeSchema = [
    {
      name: "name",
      type: "text",
      label: "Name",
      placeholder: "Enter your Name",
      validation: "required",
    },
    {
      name: "email",
      type: "email",
      label: "Email",
      placeholder: "Enter your Email",
      validation: "required|email",
    },
    {
      name: "phone",
      type: "text",
      label: "Phone Number",
      placeholder: "Enter your Contact Number",
      validation: "required",
    },
    {
      name: "dateOfBirth",
      type: "date",
      label: "Date of Birth",
      validation: "required",
    },
    {
      name: "department",
      type: "dropdown",
      label: "Select Department",
      options: departmentName,
      validation: "required",
    },
    {
      name: "designation",
      type: "conditionalDropdown",
      label: "Designation",
      options: designationName,
      placeholder: "Enter Employee Designation",
      validation: "required",
    },
    {
      name: "employeeStatus",
      type: "dropdown",
      label: "Select Employee Status",
      options: newEmployeeTypes,
      validation: "required",
    },
    {
      name: "role",
      type: "dropdownWithArrayObjectOption",
      label: "Select Employee Role",
      options: roles,
      valueToBeRender: "name",
      validation: "required",
    },
    {
      name: "reportingTo",
      type: "dropdownWithArrayObjectOption",
      label: "Reporting To",
      options: allEmployees,
      valueToBeRender: "name",
      validation: "required",
    },
  ];

  if(isEmployeesFetching || isRolesFetching){
    return(
      <MiniLoader />
    )
  }

  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Add Employee" />

      <div className="add-emp-form-wrapper custom-padding">
        <DynamicForm
          schema={newEmployeeSchema}
          httpMethod="post"
          apiEndPoint="employee/add"
        />
      </div>
    </>
  );
};

export default AddEmployee;
