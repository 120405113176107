import "./NewTaskTable.css";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";

import useCurrentUser from "../../custom-hooks/useCurrentUser";
import { getBGColorForTaskStatus, getColorForPriority, getStatus } from "../../config";
import NoResults from "../no-results/NoResults";

const NewTaskTable = ({
  tasks,
  handleEditClick,
  handleSaveClick,
  handleDeleteClick,
  handleInputChange,
  customTtClass,
  currentProject,
  teams,
  getEmployeeIdAndName,
  projectLeads
}) => {

  const currentUser = useCurrentUser();


  // const [tasks, setTasks] = useState([
  //   {
  //     id: uuidv4(),
  //     summary: "Task 1",
  //     deadline: "2024-07-10",
  //     status: "Pending",
  //   },
  //   {
  //     id: uuidv4(),
  //     summary: "Task 2",
  //     deadline: "2024-07-11",
  //     status: "Completed",
  //   },
  // ]);

  // const [editingTaskId, setEditingTaskId] = useState(null);
  // const [editedTask, setEditedTask] = useState({
  //   summary: "",
  //   deadline: "",
  //   status: "",
  // });

  // const handleEditClick = (task) => {
  //   setEditingTaskId(task.id);
  //   setEditedTask({
  //     summary: task.summary,
  //     deadline: task.deadline,
  //     status: task.status,
  //   });
  // };

  // const handleDeleteClick = (taskId) => {
  //   // setTasks(tasks.filter((task) => task.id !== taskId));
  // };

  // // const handleSaveClick = () => {
  // //   setTasks(
  // //     tasks.map((task) =>
  // //       task.id === editingTaskId ? { ...task, ...editedTask } : task
  // //     )
  // //   );
  // //   setEditingTaskId(null);
  // //   setEditedTask({ summary: "", deadline: "", status: "" });
  // // };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setEditedTask((prevState) => ({ ...prevState, [name]: value }));
  // };

  const [showFullText, setShowFullText] = useState([]);

  const toggleFullText = (index) => {
    setShowFullText((prev) => {
      const newShowFullText = [...prev];
      newShowFullText[index] = !newShowFullText[index];
      return newShowFullText;
    });
  };




  return (
    <>
      <table className={`tasks-table nt-table ${customTtClass}`}>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Project</th>
            <th className="tt-col-width">Task Summary</th>
            <th>Task Deadline</th>
            <th>Task Priority</th>
            <th>Task Status</th>
            <th>Assigned To</th>
            <th>Added By</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>

          {/* {tasks.map((task, index) => (
          <tr key={task.id}>
            <td>{index + 1}</td>
            <td>
              {editingTaskId === task.id ? (
                <input
                  type="text"
                  name="summary"
                  value={editedTask.summary}
                  onChange={handleInputChange}
                />
              ) : (
                task.summary
              )}
            </td>
            <td>
              {editingTaskId === task.id ? (
                <input
                  type="date"
                  name="deadline"
                  value={editedTask.deadline}
                  onChange={handleInputChange}
                />
              ) : (
                task.deadline
              )}
            </td>
            <td>
              {editingTaskId === task.id ? (
                <select
                  name="status"
                  value={editedTask.status}
                  onChange={handleInputChange}
                >
                  <option value="Completed">Completed</option>
                  <option value="In Progress">In Progress</option>
                  <option value="On Hold">On Hold</option>
                </select>
              ) : (
                task.status
              )}
            </td>
            <td>
              {editingTaskId === task.id ? (
                <button onClick={handleSaveClick} className="common-btn">
                  Save
                </button>
              ) : (
              <>
                <FaEdit
                  onClick={() => handleEditClick(task)}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                />
                <FaTrash
                  onClick={() => handleDeleteClick(task.id)}
                  style={{ cursor: "pointer" }}
                />
              </>
              )}
            </td>
          </tr>
        ))} */}
          {tasks.length > 0 &&  tasks?.map(
            (task, index) =>
              !task.isDeleted && (
                <>
                  <tr key={task.id} className="action-col">
                    <td>{index + 1}</td>
                    <td className="text-capitalize">{currentProject}</td>
                    <td>
                      {task?.isOnEditMode ? (
                        <input
                          type="text"
                          name="summary"
                          value={task?.summary}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      ) : (
                        task?.summary
                      )}
                    </td>
                    <td>
                      {task?.isOnEditMode ? (
                        <input
                          type="date"
                          name="endDate"
                          value={task?.endDate}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      ) : (
                        task?.endDate
                      )}
                    </td>
                    <td className="text-capitalize" style={{ color: getColorForPriority(task?.priority) }}>
                      {task?.isOnEditMode ? (
                        <select
                          name="priority"
                          value={task?.priority || ""}
                          onChange={(e) => handleInputChange(e, index)}
                        >
                          <option value="" disabled>
                            Select priority
                          </option>
                          <option value="low">Low</option>
                          <option value="mid">Mid</option>
                          <option value="high">High</option>
                        </select>
                      ) : (
                        task?.priority
                      )}
                    </td>
                    <td style={{ width: "15%" }}>
                      {task?.isOnEditMode || (task?.addedBy?.id === currentUser?._id) || (task?.assignedTo?.id === currentUser?._id) ? (
                        <select
                          name="status"
                          value={task?.status}
                          onChange={(e) => handleInputChange(e, index)}
                          style={{ backgroundColor: getBGColorForTaskStatus(task?.status), color: "#ffffff" }}
                        >
                          <option value="" disabled>
                            Select status
                          </option>
                          <option value="inactive">Inactive</option>
                          <option value="in progress">In Progress</option>
                          <option value="paused">Paused</option>
                          <option value="completed">Completed</option>
                          <option value="suspended">Suspended</option>
                        </select>
                      ) : (
                        // task?.status && getStatus("task", task?.status)
                        <select
                          name="status"
                          value={task?.status}
                          onChange={(e) => handleInputChange(e, index)}
                          style={{ backgroundColor: getBGColorForTaskStatus(task?.status), color: "#ffffff" }}
                          disabled
                          className="text-capitalize" 
                        >
                          <option value={task?.status}>{task?.status}</option>
                        </select>
                      )}
                    </td>
                    <td className="text-capitalize">
                      {task?.isOnEditMode ? (
                        <select
                          name="assignedTo"
                          value={task?.assignedTo?.id}
                          onChange={(e) => handleInputChange(e, index)}
                          className="text-capitalize"
                        >
                          <option value="" disabled selected>
                            Select member
                          </option>
                          {/* {
                          // teams?.map((team, index) => {
                          //   return (
                          //     <option value={team}>{getEmployeeIdAndName(team)?.name}</option>
                          //   )
                          // })
                          
                        } */}

                          {/* Combine teams and projectLeads */}
                          {[...(teams || []), ...(projectLeads || [])]?.map((member, index) => (
                            <option key={index} value={member}>
                              {getEmployeeIdAndName(member)?.name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        // task?.assignedTo?.name ? task?.assignedTo.name : "N.A."
                        // {
                        task?.assignedTo?.id === currentUser?._id ? (
                          "You"
                        ) : (
                          task?.assignedTo?.name ? task?.assignedTo.name : "N.A."
                        )
                        // }
                      )}
                    </td>
                    <td>
                      {/* {task?.isOnEditMode ? ( */}
                      {task?.addedBy?.id === currentUser?._id ||
                        task._id === null ? (
                        <>You</>
                      ) : (
                        task?.addedBy?.name
                      )}
                      {/* {task?.addedBy?.name} */}
                      {/* ) : (
                task?.status
              )} */}
                    </td>
                    <td>
                      {/* {task?.isOnEditMode ? (
                <button onClick={handleSaveClick} className="common-btn">
                  Save
                </button>
              ) : ( */}
                      <>
                        {!task.isOnEditMode &&
                          (task?.addedBy?.id === currentUser?._id) ? (
                          <FaEdit
                            onClick={() => handleEditClick(task)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          />
                        ) : (
                          <FaEdit
                            style={{ cursor: "pointer", marginRight: "10px" }}
                            className="not-clickable"
                          />
                        )}
                        {task?.addedBy?.id === currentUser?._id ||
                          currentUser?.isUserDepartmentHead ||
                          task._id === null ? (
                          <FaTrash
                            onClick={() => handleDeleteClick(index)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <FaTrash
                            style={{ cursor: "pointer" }}
                            className="not-clickable"
                          />
                        )}
                      </>
                      {/* )} */}
                    </td>
                  </tr>
                </>
              )
          )}
        </tbody>
      </table>
      {
        !tasks.length > 0 && (
          <NoResults />
        )
      }
    </>
  );
};

export default NewTaskTable;
