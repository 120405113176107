import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const DynamicFieldsBasedOnCheckbox = ({
    label = "Default Label",
    checkBoxDependencyName = "",
    values = {},
    // handleChange = () => { },
    onChangeFunction,
    numberOfInputFields = 1,
    inputFieldConfig = [],
    fieldWidth,
    checkBoxDependencyLabel
}) => {

    const defaultInputFiedColumnWidth = 4;

    const handleInputChange = (e) => {
        onChangeFunction(e);
    };

    return (
        <Form.Group className="mb-3" controlId="form.ControlInput1">
            <Form.Label>{label}</Form.Label>
            {values[checkBoxDependencyName]?.length > 0 ? (
                values[checkBoxDependencyName]?.map((dependencyValue, index) => (
                    <div key={index}>
                        <Form.Label>{`Enter details for ${dependencyValue}`}</Form.Label>
                        {/* <h5>{`Enter details for ${dependencyValue}`}</h5> */}
                        {[...Array(numberOfInputFields)]?.map((_, fieldIndex) => (
                            <Row key={fieldIndex}>
                                {inputFieldConfig?.map((fieldConfig, idx) => (
                                    <Col sm={fieldWidth ? fieldWidth : defaultInputFiedColumnWidth}>
                                        <Form.Group className="mb-3" controlId={`${dependencyValue}_${fieldConfig?.name}_${fieldIndex}`} key={idx}>
                                            <Form.Label>{fieldConfig?.label}</Form.Label>
                                            <Form.Control
                                                name={`${fieldConfig?.name}[${index}][${fieldIndex}]`}
                                                value={values[fieldConfig?.name]?.[index]?.[fieldIndex] || ""}
                                                onChange={(e) => handleInputChange(e, dependencyValue, fieldConfig?.name, index)}
                                                // onChange={onChangeFunction}
                                                placeholder={fieldConfig?.placeholder}
                                                type={fieldConfig?.type || "text"}
                                            />
                                        </Form.Group>
                                    </Col>
                                ))}
                            </Row>
                        ))}
                    </div>
                ))
            ) : !checkBoxDependencyName ? (
                <p> Kindly assign a dependency input field first.</p>

            ) : (
                <p>Please select options from {checkBoxDependencyLabel} first.</p>
            )
            }
        </Form.Group >
    );
};

export default DynamicFieldsBasedOnCheckbox;
