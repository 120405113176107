export const departmentName = [
  "Web Department",
  // "Brand Marketing",
  // "Performance Marketing",
  // "Content Department",
  "Digital Department",
  "Graphic Department",
  "Account Department",
];

// export const designationName = ['Trainee', 'Executve','Frontend Developer', 'Backend Developer',];
export const designationName = [
  {
    department: "Web Department",
    designations: [
      "Trainee",
      "Full Stack Developer",
      "Frontend Developer",
      "Backend Developer",
    ],
  },
  {
    department: "Digital Department",
    designations: ["Trainee", "Team Lead", "Executive"],
  },
  // {
  //   department: "Brand Marketing",
  //   designations: ["Trainee", "Team Lead", "Executive"],
  // },
  // {
  //   department: "Performance Marketing",
  //   designations: ["Trainee", "Team Lead", "Executive"],
  // },
  // {
  //   department: "Content Department",
  //   designations: ["Trainee", "Team Lead", "Executive"],
  // },
  {
    department: "Graphic Department",
    designations: [
      "Trainee",
      "Team Lead",
      "Graphic Designer",
      "Sr. Graphic Designer",
    ],
  },
  {
    department: "Account Department",
    designations: ["Trainee", "Accounting Manager", "Executive"],
  },
];

export const newEmployeeTypes = ["intern", "full-time"];
export const oldEmployeeTypes = [
  "intern",
  "full-time",
  "former",
  "notice-period",
];
// export const employeeLevel = ['employee', 'team-head',];
export const employeeLevel = ["employee", "team-head"];
export const clientStatus = ["active", "former", "maintenance"];

export const clientServices = [
  "website development",
  "performance marketing",
  "brand marketing",
  "graphics",
  "content management",
];
// export const clientStatus = {
//   ACTIVE: "active",
//   FORMER: "former",
//   MAINTENANCE: "maintenance"
// }

export const intentOptionForUserCalenderForm = [
  "Brand",
  "Knowledge Sharing",
  "Entertain",
  "Inspire",
  "Convince",
  "Sales",
  "Engage",
  "Special Days",
];

export const typeOfPostOptionsForUserCalenderForm = [
  "Single Image",
  "Carousel",
  "Reel",
  "Video",
  "Text",
  "Poll",
];

export const statusOptionsForUserCalenderForm = [
  "Rejected",
  "Delayed",
  "Posted",
  "Scheduled",
  "Approved",
  "Under Process",
];

export const serverRequestType = {
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const priority = ["High", "Med", "Low"];
export const status = [
  "Suspended",
  "In Progress",
  "Delayed",
  "Completed",
  "Inactive",
  "Paused",
];
// export const status = {
//   ACTIVE: "active",
//   FORMER: "former",
//   MAINTENANCE: "maintenance",
//   OPEN: "open",
//   INPROGRESS: "in-progress",
//   CLOSED: "closed",
//   COMPLETED: "completed"
// }
export const leaveType = ["Full Day Leave", "Half Day Leave", "Work From Home"];

export const validate = (values, schema) => {
  const errors = {};

  schema?.forEach((field) => {
    if (
      field?.validation?.includes("required") &&
      (!values[field.name] || values[field.name].length < 1)
    ) {
      errors[field.name] = "This field is required";
    }
    if (field?.validation?.includes("email") && values[field.name]) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(values[field.name])) {
        errors[field.name] = "Invalid email address";
      }
    }
  });

  return errors;
};

export const deepEqualCheckForTwoArray = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;
  for (let i = 0; i < arr1?.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return false;
  }
  return true;
};

export const getStatus = (statusType, status) => {
  if (statusType === "task") {
    switch (status) {
      case "in progress":
        return "In Progress";
      case "completed":
        return "Completed";
      case "suspended":
        return "Suspended";
      case "delayed":
        return "Delayed";
      case "inactive":
        return "Inactive";
      case "paused":
        return "Paused";
      default:
        return "Unassigned";
    }
  }
  return "Invalid Status Type";
};

export const changeDateFormat = (date) => {
  console.log("coming data to change format ==>", date)
  const fetchedDate = new Date(date);
  const formattedDate = fetchedDate.toLocaleDateString();
  return formattedDate;
};


// Function to extract unique departments
export const getUniqueDepartments = (employees) => {
  const departmentSet = new Set();
  employees?.forEach((employee) => {
    if (employee?.department) {
      departmentSet?.add(employee.department);
    }
  });
  return Array?.from(departmentSet)?.map((department) => ({
    label: department,
    value: department,
  }));
};

// Function to extract unique employee statuses
export const getUniqueEmployeeStatuses = (employees) => {
  const statusSet = new Set();
  employees?.forEach((employee) => {
    if (employee?.employeeStatus) {
      statusSet.add(employee.employeeStatus);
    }
  });
  return Array?.from(statusSet)?.map((status) => ({
    label: status,
    value: status,
  }));
};

// Function to extract unique assignedTaskUser
export const getUniqueAssignedTaskUser = (tasks) => {
  const userSet = new Set();
  tasks?.forEach((task) => {
    if (task?.employeeStatus) {
      userSet.add({ employeeId: task.employeeStatus });
    }
  });
  return Array?.from(userSet)?.map((status) => ({
    label: status,
    value: status,
  }));
};


export const isMeetingLive = (startTime, endTime) => {
  const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });
  return currentTime >= startTime && currentTime <= endTime;
};



export const getColorForPriority = (priority) => {
  switch (priority) {
    case "high":
      return "var(--Red)";

    case "mid":
      return "var(--Orange)";

    case "low":
      return "var(--Green)";

    default:
      return "#FFFFFF";
  }
};

export const getColorForStatus = (status) => {
  switch (status) {
    case "pending":
      return "#EE534F";

    case "in progress":
      return "#FEB056";

    case "completed":
      return "#A2CD48";

    case "delayed":
      return "var(--Red)";

    default:
      return "#FFFFFF";
  }
};

export const getBGColorForTaskStatus = (status) => {
  switch (status) {
    case "inactive":
      return "var(--Grey)";

    case "in progress":
      return "#FEB056";

    case "completed":
      return "var(--Green)";

    case "delayed":
      return "var(--Red)";

    case "paused":
      return "var(--DAqua)";

    case "suspended":
      return "#000000";

    default:
      return "#FFFFFF";
  }
};

export const priorityOptions = [
  { label: "Low", value: "low" },
  { label: "Mid", value: "mid" },
  { label: "High", value: "high" },
];

export const statusOptions = [
  { label: "Inactive", value: "inactive" },
  { label: "In Progress", value: "in progress" },
  { label: "Delayed", value: "delayed" },
  { label: "Paused", value: "paused" },
  { label: "Completed", value: "completed" },
  { label: "Suspended", value: "suspended" },
];

export const PERMISSIONS = [
  'add_employee',
  'edit_employee',
  'delete_employee',
  'add_project',
  'edit_project',
  'delete_project',
  'add_client',
  'edit_client',
  'delete_client',
  'add_role',
  'edit_role',
  'delete_role',
];


export const hasPermission = (currentUser, requiredPermissions = []) => {
  if (!currentUser) return false;

  // Organisation owner has all permissions
  if (currentUser?.userType === "organisation-owner") return true;

  // Check if the user has any of the required permissions
  if (currentUser?.role?.status && currentUser?.role?.permissions) {
    return requiredPermissions?.some(permission =>
      currentUser?.role?.permissions?.includes(permission)
    );
  }

  return false; // Default to false if no permissions match
};