import { Col, Container, Row } from "react-bootstrap";
import "./Meetings.css";
import MeetingCard from "../../../common-components/cards/meeting-card/MeetingCard";
import OnlineMeeting from "../../../common-components/online-meeting/OnlineMeeting";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMeetings } from "../../../redux/apiCalls";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";

const Meetings = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    getAllMeetings(dispatch);
  },[dispatch]);

  const {meetings, isFetching} = useSelector((state) => state.meeting);
  // meeting data
  const meetingData = [
    {
      status: "upcoming",
      clientName: "RRPL",
      projectName: "Landing Page",
      dateTime: "Feb 24, 10:30 - 11:00",
    },
    {
      status: "today",
      clientName: "RRPL",
      projectName: "Landing Page",
      dateTime: "Feb 24, 10:30 - 11:00",
    },
  ];

  if(isFetching){
    return(
      <MiniLoader />
    )
  }

  return (
    <>
      {/* Meetings  */}
      <Breadcrumb mainTitle="Upcoming Meetings" />
      <MeetingCard meetings={meetings} />
      <OnlineMeeting />
    </>
  );
};

export default Meetings;