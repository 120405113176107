import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./DataTable.css";
import { Col, Container, Row, Table } from "react-bootstrap";
import { IoEyeOutline } from "react-icons/io5";
import { getColorForPriority, getColorForStatus, getStatus } from "../../config";
import useCurrentUser from "../../custom-hooks/useCurrentUser";
import { FaEdit, FaTrash } from "react-icons/fa";

const DataTable = ({ tasks }) => {

  const currentUser = useCurrentUser();

  // const getColorForPriority = (priority) => {
  //   switch (priority) {
  //     case "high":
  //       return "var(--Red)";

  //     case "mid":
  //       return "var(--Orange)";

  //     case "low":
  //       return "var(--Green)";

  //     default:
  //       return "#FFFFFF";
  //   }
  // };

  // const getColorForStatus = (status) => {
  //   switch (status) {
  //     case "pending":
  //       return "#EE534F";

  //     case "inProgress":
  //       return "#FEB056";

  //     case "completed":
  //       return "#A2CD48";

  //     default:
  //       return "#FFFFFF";
  //   }
  // };

  return (
    <section className="tasks-table-wrapper">
      <Container>
        <Row>
          <table className="tasks-table">
            <thead>
              <tr>
                <th className="tt-sno">S. No.</th>
                <th className="tt-desc">Task Summary</th>
                <th className="tt-project">Project</th>
                <th className="tt-priority">Task Priority</th>
                <th className="tt-priority">Task Deadline</th>
                <th className="tt-status">status</th>
                <th>Added By</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {tasks?.map((task, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {/* <td>{task.taskID}</td> */}
                    <td>{task?.summary}</td>
                    <td>{task?.projectTitle}</td>
                    <td style={{ color: getColorForPriority(task?.priority) }}>
                      {task?.priority}
                    </td>
                    <td>{task?.endDate}</td>
                    <td style={{ color: getColorForStatus(task?.status) }}>
                      {getStatus("task", task?.status)}
                    </td>
                    <td>
                      {/* <IoEyeOutline /> */}
                      {
                        task?.addedBy?.id === currentUser?._id || task._id === null ? (
                          <>You</>
                        ) : (
                          task?.addedBy?.name
                        )
                      }
                    </td>
                    {/* <td>
                      <>
                        {
                          !task.isOnEditMode && (task?.addedBy?.id === currentUser?._id) ? (
                            <FaEdit
                              onClick={() => handleEditClick(task)}
                              style={{ cursor: "pointer", marginRight: "10px" }}
                            />
                          ) : (
                            <FaEdit
                              style={{ cursor: "pointer", marginRight: "10px" }}
                              className="not-clickable"
                            />
                          )
                        }
                        {
                          task?.addedBy?.id === currentUser?._id || currentUser?.isUserDepartmentHead || task._id === null ? (
                            <FaTrash
                              onClick={() => handleDeleteClick(index)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <FaTrash
                              style={{ cursor: "pointer" }}
                              className="not-clickable"
                            />
                          )
                        }
                      </>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
      </Container>
    </section>
  );
};

export default DataTable;
