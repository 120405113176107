import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { clientServices, serverRequestType } from "../../../../config";
import { clientSchema } from "../../../../formSchema";
import DynamicForm from "../../../../common-components/form/DynamicForm";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";

const EditProject = () => {
  const { id: projectId } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const isUserFetching = useSelector((state) => state.allUsers.isFetching);
  const allClients = useSelector((state) => state.client?.clients);
  const allEmployees = useSelector((state) => state.employee?.employees);
  const currentProject = useSelector((state) =>
    state.projects?.projects?.find((project) => project._id === projectId)
  );

  const projectSchema = [
    {
      name: "title",
      type: "text",
      label: "Project Title",
      placeholder: "Enter project title",
      validation: "required",
    },
    {
      name: "clientId",
      type: "dropdownWithArrayObjectOption",
      label: "Client Name",
      options: allClients,
      valueToBeRender: "name",
      validation: "required",
    },
    {
      name: "startDate",
      type: "date",
      label: "Onboard Date",
      validation: "date",
    },
    {
      name: "endDate",
      type: "date",
      label: "Deadline",
      validation: "date",
    },
    {
      name: "projectLeads",
      type: "dropdownWithArrayObjectOption",
      label: "Project Leads",
      options: allEmployees,
      valueToBeRender: "name",
      multiple: true,
    },
    {
      name: "teams",
      type: "dropdownWithArrayObjectOption",
      label: "Select team memebers",
      options: allEmployees,
      valueToBeRender: "name",
      multiple: true,
    },
    {
      name: "priority",
      type: "dropdown",
      label: "Select project priority",
      options: ["low", "medium", "high"],
    },
    {
      name: "status",
      type: "dropdown",
      label: "Select project status",
      options: ["inactive", "in progress", "delayed", "paused", "completed", "suspended"],
    },
  ];

  useEffect(() => {
    setInitialValues(currentProject);
  }, [currentProject]);

  if (isUserFetching) {
    return <h1>Fetching</h1>;
  }

  return (
    // <>
    // {
    initialValues ? (
      <>
        {/* breadcrumb  */}
        <Breadcrumb mainTitle="Update Details" />

        {/* employee details update form */}
        <div className="emp-detail-update-form custom-padding">
          <DynamicForm
            schema={projectSchema}
            initialValues={initialValues}
            httpMethod={serverRequestType.PUT}
            apiEndPoint={`project/${projectId}`}
            buttonName="Update"
          />
        </div>
      </>
    ) : (
      <h1>Loading....</h1>
    )
    // }

    // </>
  );
};

export default EditProject;
