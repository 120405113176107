import { Col, Container, Row } from "react-bootstrap";
import "./Employees.css";
import AnnouncementBar from "../../../common-components/announcement-bar/AnnouncementBar";
import HeaderNav from "../sidebar/HeaderNav";
import EmployeeCard from "../../../common-components/cards/employee-card/EmployeeCard";
import CommonButton from "../../../common-components/button/CommonButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllEmployees, getAllUsers } from "../../../redux/apiCalls";
import useCurrentUser from "../../../custom-hooks/useCurrentUser";
import MiniLoader from "../../../common-components/miniLoader/MiniLoader";
import Breadcrumb from "../../../common-components/breadcrumb/Breadcrumb";
import DropDownButton from "../../../common-components/dropdown-button/DropDownButton";
import { getUniqueDepartments, getUniqueEmployeeStatuses, hasPermission } from "../../../config";
import NoResults from "../../../common-components/no-results/NoResults";
const Employees = () => {

  const state = useSelector((state) => state);
  const { isFetching, employees: allEmployees } = useSelector(
    (state) => state?.employee
  );


  console.log("enmployee data ==>", allEmployees)
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const organisationId = currentUser?.isUserOrganisationOwner
      ? currentUser._id
      : currentUser?.organisationId;
    getAllEmployees(dispatch, organisationId);
  }, [dispatch]);

  useEffect(() => {
    setFilteredEmployees(allEmployees)
  }, [allEmployees])


  // State for filtered employees
  const [filteredEmployees, setFilteredEmployees] = useState(allEmployees);
  const uniqueDepartments = getUniqueDepartments(allEmployees);
  const uniqueEmployeeStatuses = getUniqueEmployeeStatuses(allEmployees);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDateOrder, setSelectedDateOrder] = useState(null);

  // State for active filters
  const [activeFilters, setActiveFilters] = useState({
    department: null,
    status: null,
    dateOrder: null,
  });

  // Apply all filters
  const applyFilters = () => {
    let filtered = [...allEmployees];

    // Filter by department
    if (activeFilters?.department) {
      filtered = filtered.filter(
        (employee) => employee.department === activeFilters.department
      );
    }

    // Filter by status
    if (activeFilters?.status) {
      filtered = filtered.filter(
        (employee) => employee.employeeStatus === activeFilters.status
      );
    }

    // Sort by date
    if (activeFilters?.dateOrder) {
      filtered.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return activeFilters.dateOrder === "New to Old"
          ? dateB - dateA
          : dateA - dateB;
      });
    }
    setFilteredEmployees(filtered);
  };

  // Update department filter
  const handleDepartmentFilter = (department) => {
    setSelectedDepartment(department);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      department,
    }));
  };

  // Update status filter
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      status,
    }));
  };

  // Update date sorting filter
  const handleDateSort = (order) => {
    setSelectedDateOrder(order)
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      dateOrder: order,
    }));
  };

  // Reset all filters
  const resetFilters = () => {
    setActiveFilters({
      department: null,
      status: null,
      dateOrder: null,
    });
    setSelectedDepartment(null);
    setSelectedStatus(null);
    setSelectedDateOrder(null);
    setFilteredEmployees(allEmployees);
  };

  // Reapply filters whenever activeFilters changes
  useEffect(() => {
    applyFilters();
  }, [activeFilters]);

  if (isFetching) {
    return <MiniLoader />;
  }

  return (
    <div className="employees-wrapper">
      <Container fluid className="p-0">
        <Row>
          <Col lg={12}>
            {/* breadcrumb */}
            <Breadcrumb mainTitle="Employees" />
            <div className="custom-padding">
              <div className="action-btn-wrapper cp-card-section pb-0">
                {hasPermission(currentUser, ["add_employee"]) && (
                    <Link to={"/add-employee"}>
                      <CommonButton
                        btnName="Add Employee"
                        btnClass="btn-edit common-btn"
                      />
                    </Link>
                  )}

                {/* Department Dropdown */}
                <DropDownButton
                  label="Sort by Department"
                  options={uniqueDepartments}
                  onSelect={(selected) => handleDepartmentFilter(selected)}
                  selected={selectedDepartment} // Pass selected value
                />

                {/* Employee Status Dropdown */}
                <DropDownButton
                  label="Sort by Status"
                  options={uniqueEmployeeStatuses}
                  onSelect={handleStatusFilter}
                  selected={selectedStatus} // Pass selected value

                />

                {/* Date Sorting Dropdown */}
                <DropDownButton
                  label="Sort by Joining"
                  options={[
                    { label: "New to Old", value: "New to Old" },
                    { label: "Old to New", value: "Old to New" },
                  ]}
                  onSelect={handleDateSort}
                  selected={selectedDateOrder} // Pass selected value

                />
                {/* Reset Filters Button */}
                <CommonButton
                  onClickAction={resetFilters}
                  btnClass='btn-edit'
                  btnName="Reset Filters"
                />
              </div>



              {/* employee cards */}
              {
                filteredEmployees?.length > 0 ? (
                  // return(
                    <EmployeeCard details={filteredEmployees} />
                  // )
                ) : (
                  // <p>No results found</p>
                  <NoResults />
                )
              }
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Employees;
