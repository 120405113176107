import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../common-components/breadcrumb/Breadcrumb';
import DynamicForm from '../../common-components/form/DynamicForm';
import { serverRequestType } from '../../config';
import { getAllEmployees } from '../../redux/apiCalls';
import useOrganisationId from '../../custom-hooks/useOrganisationId';

const EditMeeting = () => {
    const { id: meetingId } = useParams();
    const [initialValues, setInitialValues] = useState(null);
    const isUserFetching = useSelector((state) => state.meeting?.isFetching);
    const currentMeeting = useSelector((state) =>
        state.meeting?.meetings?.find((employee) => employee?._id === meetingId)
    );

    const dispatch = useDispatch();
    const organisationId = useOrganisationId();

    useEffect(() => {
        getAllEmployees(dispatch, organisationId);
    }, [dispatch]);

    // const state = useSelector((state) => state);
    const allClients = useSelector((state) => state.client?.clients);
    const { employees: allEmployees, isFetching } = useSelector((state) => state.employee);

    const meetingSchema = [
        {
            name: "agenda",
            type: "text",
            label: "Meeting Agenda",
            placeholder: "Enter meeting agenda",
            validation: "required",
        },
        {
            name: "link",
            type: "text",
            label: "Meeting Link",
            placeholder: "Enter meeting link",
            validation: "required",
        },
        // {
        //     name: "clientId",
        //     type: "dropdownWithArrayObjectOption",
        //     label: "Client Name",
        //     options: allClients,
        //     valueToBeRender: "name",
        //     // multiple : true,
        //     // placeholder: "Enter brand name",
        //     validation: "required",
        // },
        {
            name: "meetingDate",
            type: "date",
            label: "Date",
            validation: "date",
        },
        {
            name: "startTime",
            type: "time",
            label: "meeting start time",
            validation: "time",
        },
        {
            name: "endTime",
            type: "time",
            label: "meeting end time",
            validation: "time",
        },
        // {
        //     name: "projectLeads",
        //     type: "dropdownWithArrayObjectOption",
        //     label: "Project Leads",
        //     options: allEmployees,
        //     valueToBeRender: "name",
        //     multiple: true,
        // },
        // {
        //   name: "projectPointOfContact",
        //   type: "text",
        //   label: "Point of contact",
        //   placeholder: "Enter person name",
        //   validation: "required",
        // },
        {
            name: "teams",
            type: "dropdownWithArrayObjectOption",
            label: "Select participents",
            options: allEmployees,
            valueToBeRender: "name",
            multiple: true,
        },
        // {
        //     name: "priority",
        //     type: "dropdown",
        //     label: "Select project priority",
        //     options: ["low", "medium", "high"],
        // },
        // {
        //     name: "status",
        //     type: "dropdown",
        //     label: "Select project status",
        //     options: ["inactive", "in progress", "delayed", "paused", "completed", "suspended"],
        // },
    ];

    useEffect(() => {
        setInitialValues(currentMeeting);
    }, [currentMeeting]);

    if (isUserFetching) {
        return <h1>Fetching</h1>;
    }

    return (
        // <>
        // {
        initialValues ? (
            <>
                {/* breadcrumb  */}
                <Breadcrumb mainTitle="Update Details" />

                {/* employee details update form */}
                <div className="emp-detail-update-form custom-padding">
                    <DynamicForm
                        schema={meetingSchema}
                        initialValues={initialValues}
                        httpMethod={serverRequestType.PUT}
                        apiEndPoint={`meeting/${meetingId}`}
                        buttonName="Update"
                    />
                </div>
            </>
        ) : (
            <h1>Loading....</h1>
        )
    );
}

export default EditMeeting
