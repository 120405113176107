import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import "./LeaveWFHForm.css";
import { useEffect, useState } from "react";
import DynamicForm from "../../../../common-components/form/DynamicForm";
import { leaveFormSchema, leaveFormTypeSchema } from "../../../../formSchema";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";
import { addDays, format } from "date-fns";
import { userRequest } from "../../../../requestMethod";
import MiniLoader from "../../../../common-components/miniLoader/MiniLoader";
import Popup from "../../../../common-components/popup/Popup";
import CommonButton from "../../../../common-components/button/CommonButton";

const LeaveWFHForm = () => {
  // const [selectedLeaveType, setSelectedLeaveType] = useState("fullDay");

  // const handleLeaveTypeChange = (e) => {
  //   setSelectedLeaveType(e.target.value);
  // };

  // const renderLeaveForm = () => {
  //   switch (selectedLeaveType) {
  //     case "fullDay":
  //       return (
  //         <section className="leave-form-container custom-padding">
  //           <div className="form-name">
  //             <h5>Full Day Leave Form</h5>
  //           </div>
  //           <div className="leave-form">
  //             <DynamicForm schema={leaveFormSchema} />
  //           </div>
  //         </section>
  //       );
  //     case "halfDay":
  //       return (
  //         <section className="leave-form-container custom-padding">
  //           <div className="form-name">
  //             <h5>Half Day Leave Form</h5>
  //           </div>
  //           <div className="leave-form">
  //             <DynamicForm schema={leaveFormSchema} />
  //           </div>
  //         </section>
  //       );
  //     case "wfh":
  //       return (
  //         <section className="leave-form-container custom-padding">
  //           <div className="form-name">
  //             <h5>Work From Home Form</h5>
  //           </div>
  //           <div className="leave-form">
  //             <DynamicForm schema={leaveFormSchema} />
  //           </div>
  //         </section>
  //       );
  //     default:
  //       return null;
  //   }
  // };
  // return (
  //   <>
  //     {/* breadcrumb  */}
  //     <Breadcrumb mainTitle="Leaves" />

  //     <section className="leave-form-type-wrapper custom-padding">
  //       <div className="leave-form-type">
  //         {/* full day leave  */}
  //         <div className="full-day-leave">
  //           {/* <DynamicForm schema={leaveFormTypeSchema} /> */}
  //           <input
  //             type="radio"
  //             name="leaveType"
  //             id="fullDay"
  //             value="fullDay"
  //             checked={selectedLeaveType === "fullDay"}
  //             onClick={handleLeaveTypeChange}
  //             className="mr-5"
  //           />
  //           <label htmlFor="fullDay">Full Day Leave</label>
  //         </div>

  //         {/* half day leave  */}
  //         <div className="half-day-leave">
  //           <input
  //             type="radio"
  //             name="leaveType"
  //             id="halfDay"
  //             value="halfDay"
  //             checked={selectedLeaveType === "halfDay"}
  //             onClick={handleLeaveTypeChange}
  //             className="mr-5"
  //           />
  //           <label htmlFor="halfDay">Half Day Leave</label>
  //         </div>

  //         {/* Work from home  */}
  //         <div className="work-from-home">
  //           <input
  //             type="radio"
  //             name="leaveType"
  //             id="wfh"
  //             value="wfh"
  //             checked={selectedLeaveType === "wfh"}
  //             onClick={handleLeaveTypeChange}
  //             className="mr-5"
  //           />
  //           <label htmlFor="wfh">Work From Home</label>
  //         </div>
  //       </div>
  //     </section>
  //     {renderLeaveForm()}
  //   </>
  // );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [leavesInfo, setLeavesInfo] = useState({ totalLeaves: '', days: [] });
  const [days, setDays] = useState([]);
  const [reason, setReason] = useState("");
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupStatus, setPopupStatus] = useState(true);
  const [popupText, setPopupText] = useState("Data has been added");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const dayArray = [];

      for (let d = start; d <= end; d = addDays(d, 1)) {
        const dayOfWeek = d.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          // Exclude Sundays (0) and Saturdays (6)
          dayArray.push({
            date: format(d, "yyyy-MM-dd"),
            nameOfTheDay: format(d, "EEEE"),
            typeOfLeave: "",
          });
        }
      }

      setDays(dayArray);
    } else {
      setDays([]);
    }
  }, [startDate, endDate]);

  // const handleDateChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "startDate") {
  //     setStartDate(value);
  //   } else if (name === "endDate") {
  //     setEndDate(value);
  //   }
  // };


  // const handleDateChange = (e) => {
  //   const { name, value } = e.target;

  //   // Prevent selection of past dates
  //   const today = new Date().toISOString().split("T")[0];
  //   if (value < today) {
  //     return;
  //   }

  //   if (name === "startDate") {
  //     // Update the start date
  //     setStartDate(value);

  //     // Ensure the end date is always after or equal to the start date
  //     if (endDate && value > endDate) {
  //       setEndDate(value);
  //     }
  //   } else if (name === "endDate") {
  //     // Prevent setting the end date earlier than the start date
  //     if (value >= startDate) {
  //       setEndDate(value);
  //     }
  //   }
  // };

  const today = new Date().toISOString().split("T")[0]; // Today's date in 'YYYY-MM-DD' format

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    if (name === "startDate") {
      setStartDate(value);

      // Ensure the end date is after the start date, otherwise reset the end date
      if (endDate && value > endDate) {
        setEndDate("");
      }
    } else if (name === "endDate") {
      setEndDate(value);

      // Ensure the start date is before the end date
      if (startDate && value < startDate) {
        setStartDate("");
      }
    }
  };

  const handleTypeChange = (e, index) => {
    const newDays = [...days];
    newDays[index].typeOfLeave = e.target.value;
    setDays(newDays);
  };

  const handleSubmit = async (data) => {
    setIsLoaderActive(true);
    try {
      const res = await userRequest.post("/leave/create", data);
      if (res?.status === 200) {
        setIsLoaderActive(false);
        setIsPopupActive(true);
        setPopupStatus(true);
        setPopupText(res.data);
      }
    } catch (error) {
      setIsLoaderActive(false);
      setIsPopupActive(true);
      setPopupStatus(false);
      setPopupText(error?.response?.data);
    }
  };

  const sendData = () => {
    handleSubmit({ startDate, endDate, days, reason });
  };

  const hidePopup = () => {
    setIsPopupActive(false);
  };

  // if(){

  // }

  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Leaves" />

      <section className="leave-form-type-wrapper custom-padding">
        {/* <DynamicForm /> */}
        <Form>
          <Container>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    name="startDate"
                    type="date"
                    value={startDate}
                    onChange={handleDateChange}
                    min={today} // Disallow past dates
                    max={endDate || ""} // Ensure start date is not after end date
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    name="endDate"
                    type="date"
                    value={endDate}
                    onChange={handleDateChange}
                    min={startDate || today} // Ensure end date is not before start date or today
                  />
                </FormGroup>
              </Col>
            </Row>
            {days.length > 0 && (
              <Row>
                <h5 className="pt-4">Select Leave Type for Each Day</h5>
                {days?.map((day, index) => (
                  <Col lg={4} >
                    <FormGroup key={index} >
                      <Form.Label>{`${day.nameOfTheDay}, ${day.date}`}</Form.Label>
                      <Form.Control
                        as="select"
                        value={day.typeOfLeave}
                        onChange={(e) => handleTypeChange(e, index)}
                      >
                        <option value="" disabled>
                          Select Leave Type
                        </option>
                        <option value="work_from_home">Work From Home</option>
                        <option value="half_day">Half Day</option>
                        <option value="full_day">Full Day</option>
                      </Form.Control>
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            )}
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Form.Label class="reason-label">Reason</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="reason"
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <CommonButton
              onClickAction={sendData}
              btnName="Apply"
              btnClass="btn-apply common-btn"
            />
          </Container>
        </Form>
      </section>
      {isLoaderActive && <MiniLoader />}
      {isPopupActive && (
        <Popup status={popupStatus} message={popupText} hidePopup={hidePopup} />
      )}
      {/* {renderLeaveForm()} */}
    </>
  );
};

export default LeaveWFHForm;
