import React, { useEffect } from "react";
import DynamicForm from "../../../../common-components/form/DynamicForm";
// import { clientSchema, projectSchema } from '../../../../formSchema'
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients, getAllEmployees } from "../../../../redux/apiCalls";
import useOrganisationId from "../../../../custom-hooks/useOrganisationId";
import { clientServices, clientStatus } from "../../../../config";
import Breadcrumb from "../../../../common-components/breadcrumb/Breadcrumb";

const AddProject = () => {
  // const clients = useSelector((state)=> state.user);
  const dispatch = useDispatch();
  const organisationId = useOrganisationId();

  useEffect(() => {
    getAllClients(dispatch, organisationId);
  }, [dispatch]);

  useEffect(() => {
    getAllEmployees(dispatch, organisationId);
  }, [dispatch]);

  const state = useSelector((state) => state);
  const allClients = useSelector((state) => state.client?.clients);
  const allEmployees = useSelector((state) => state.employee?.employees);

  const projectSchema = [
    {
      name: "title",
      type: "text",
      label: "Project Title",
      placeholder: "Enter project title",
      validation: "required",
    },
    {
      name: "clientId",
      type: "dropdownWithArrayObjectOption",
      label: "Client Name",
      options: allClients,
      valueToBeRender: "name",
      // multiple : true,
      // placeholder: "Enter brand name",
      validation: "required",
    },
    {
      name: "startDate",
      type: "date",
      label: "Onboard Date",
      validation: "date",
    },
    {
      name: "endDate",
      type: "date",
      label: "Deadline",
      validation: "date",
    },
    {
      name: "projectLeads",
      type: "dropdownWithArrayObjectOption",
      label: "Project Leads",
      options: allEmployees,
      valueToBeRender: "name",
      multiple: true,
    },
    // {
    //   name: "projectPointOfContact",
    //   type: "text",
    //   label: "Point of contact",
    //   placeholder: "Enter person name",
    //   validation: "required",
    // },
    {
      name: "teams",
      type: "dropdownWithArrayObjectOption",
      label: "Select team memebers",
      options: allEmployees,
      valueToBeRender: "name",
      multiple: true,
    },
    {
      name: "priority",
      type: "dropdown",
      label: "Select project priority",
      options: ["low", "medium", "high"],
    },
    {
      name: "status",
      type: "dropdown",
      label: "Select project status",
      options: ["inactive", "in progress", "delayed", "paused", "completed", "suspended"],
    },
  ];

  return (
    <>
      {/* breadcrumb  */}
      <Breadcrumb mainTitle="Add Project" />

      <div className="add-emp-form-wrapper custom-padding">
        <DynamicForm
          schema={projectSchema}
          httpMethod="post"
          apiEndPoint="project/create"
        />
      </div>
    </>
  );
};

export default AddProject;
