import { createSlice } from "@reduxjs/toolkit";

const meetingReducer = createSlice({
    name: 'meeting',
    initialState: {
        meetings: [],
        isFetching: false,
        error: false,
    },

    reducers: {
        // Get meeting actions
        getMeetingsStart: (state) => {
            state.isFetching = true;
        },
        getMeetingsSuccess: (state, action) => {
            state.isFetching = false;
            state.meetings = action.payload;
        },                                      
        getMeetingsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // add meeting actions 
        addMeetingStart: (state) => {
            state.isFetching = true;
        },

        addMeetingSuccess: (state, action) => {
            state.isFetching = false;
            const newMeeting = action.payload;
            state.meetings = [...state.meetings, newMeeting];
        },

        addUserFailure: (state) => {
            state.error = true;
        },

        // Delete meeting
        deleteMeetingStart: (state) => {
            state.isFetching = true;
        },

        deleteMeetingSuccess: (state, action) => {
            state.isFetching = false;
            state.meetings.splice(
                state.meetings.findIndex((item) => item._id === action.payload), 1
            );
        },

        deleteMeetingFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        // Update meeting
        updateMeetingStart: (state) => {
            state.isFetching = true;
        },

        updateMeetingSuccess: (state, action) => {
            state.isFetching = false;
            const adminIndex = state.meetings.findIndex(admin => admin._id === action.payload._id);
            if (adminIndex !== -1) {
                state.meetings[adminIndex] = action.payload;
            }
        },

        // updateUserFailure: (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },

        // // update user password actions
        // updatePasswordStart : (state) => {
        //     state.isFetching = true;
        //     state.error = false;
        // },

        // updatePasswordSuccess : (state, action) => {
        //     state.isFetching = false;
        //     state.meetings = action.payload;
        // },
        // updatePasswordFailure : (state) => {
        //     state.isFetching = false;
        //     state.error = true;
        // },
    },
});

export const { getMeetingsStart, getMeetingsSuccess, getMeetingsFailure, deleteMeetingStart, deleteMeetingSuccess, deleteUserFailure, addUserFailure, addMeetingStart, addMeetingSuccess, updateMeetingStart, updateMeetingSuccess, updateUserFailure, updatePasswordStart, updatePasswordSuccess, updatePasswordFailure } = meetingReducer.actions;
export default meetingReducer.reducer;

