// import { Button, Col, Container, Row, Dropdown } from "react-bootstrap";
// import "./TabComponent.css";
// import { useEffect, useState } from "react";
// import { interval } from "date-fns";
// import BarGraphReport from "../bar-graph/BarGraphReport";

// const TabComponent = ({ tabs, columns, rows, handleChangeHeader }) => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [selectedFromDate, setSelectedFromDate] = useState(null);
//   const [selectedToDate, setSelectedToDate] = useState(null);
//   const [selectedInterval, setSelectedInterval] = useState("day");
//   const [updatedHeadersData, setUpdatedHeadersData] = useState(
//     tabs[0]?.columns
//   );
//   const [selectedKPI, setSelectedKPI] = useState(columns[1]);

//   // console.log("activeTab --=>", tabs)

//   useEffect(() => {
//     handleChangeHeader(activeTab)
//   },[activeTab])

//   useEffect(() => {
//     const columns = tabs[activeTab].columns;
//     switch (selectedInterval) {
//       case "week":
//         setUpdatedHeadersData(["Week", ...columns?.slice(1)]);
//         break;
//       case "daily":
//         setUpdatedHeadersData(["Date", ...columns?.slice(1)]);
//         break;
//       case "month":
//         setUpdatedHeadersData(["Month", ...columns?.slice(1)]);
//         break;
//       case "year":
//         setUpdatedHeadersData(["Year", ...columns?.slice(1)]);
//         break;
//       default:
//         setUpdatedHeadersData(columns);
//     }
//   }, [selectedInterval, activeTab, tabs]);

//   const handleTabClick = (index) => {
//     setActiveTab(index);
//   };

//   const handleDateRangeSelection = (fromDate, toDate) => {
//     setSelectedFromDate(fromDate);
//     setSelectedToDate(toDate);
//   };

//   const handleIntervalChange = (interval) => {
//     setSelectedInterval(interval?.toLowerCase());
//   };

//   const handleKPIChange = (kpi) => {
//     setSelectedKPI(kpi);
//   };

//   const filteredData = rows
//     ?.filter((data) => {
//       const tabLabel = tabs[activeTab].label?.toLowerCase();
//       return tabLabel.includes(data.platform?.toLowerCase());
//     })
//     .filter((data) => {
//       if (selectedFromDate && selectedToDate) {
//         console.log(
//           `From Date: ${selectedFromDate}, To Date: ${selectedToDate}`
//         );

//         const fromDate = new Date(selectedFromDate.replace(/-/g, "/"));
//         const toDate = new Date(selectedToDate.replace(/-/g, "/"));
//         let dataDate;

//         try {
//           // Parsing the date string in 'DD-MM-YYYY' format
//           const [day, month, year] = data.date.split("-").map(Number);
//           dataDate = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date
//           if (isNaN(dataDate.getTime())) {
//             console.log(`Data Date: Invalid Date`);
//             console.log(`Invalid data entry:`, data);
//             return false; // Skip invalid date entries
//           }
//         } catch (error) {
//           console.error(`Error parsing date "${data.date}":`, error);
//           console.log(`Invalid data entry:`, data);
//           return false; // Skip invalid date entries
//         }

//         console.log(`Data Date: ${dataDate}`);

//         // Debugging logs to check the values being compared
//         console.log(`From Date (parsed): ${fromDate}`);
//         console.log(`To Date (parsed): ${toDate}`);
//         console.log(`Data Date (parsed): ${dataDate}`);

//         // Modify the condition to include both start and end dates
//         return dataDate >= fromDate && dataDate <= toDate;
//       }
//       return true;
//     });

//   const groupByInterval = (data, days) => {
//     const groupedData = [];
//     let intervalData = [];
//     let dayCounter = 0;

//     data.forEach((item, index) => {
//       intervalData.push(item);
//       dayCounter++;
//       if (dayCounter === days || index === data.length - 1) {
//         groupedData.push(intervalData);
//         intervalData = [];
//         dayCounter = 0;
//       }
//     });

//     return groupedData;
//   };

//   const sumObjectProperties = (objArray) => {
//     const sumObj = objArray.reduce((acc, item) => {
//       for (const key in item) {
//         if (key !== "date" && key !== "platform") {
//           if (acc.hasOwnProperty(key)) {
//             if (key.includes("Rate") || key.includes("%")) {
//               acc[key].sum += item[key];
//               acc[key].count += 1;
//             } else {
//               acc[key] += item[key];
//             }
//           } else {
//             if (key.includes("Rate") || key.includes("%")) {
//               acc[key] = { sum: item[key], count: 1 };
//             } else {
//               acc[key] = item[key];
//             }
//           }
//         }
//       }
//       return acc;
//     }, {});

//     for (const key in sumObj) {
//       if (typeof sumObj[key] === "object" && sumObj[key].count !== undefined) {
//         sumObj[key] = (sumObj[key].sum / sumObj[key].count).toFixed(2);
//       }
//     }

//     return sumObj;
//   };

//   const groupedData =
//     selectedInterval === "week"
//       ? groupByInterval(filteredData, 7)
//       : selectedInterval === "month"
//       ? groupByInterval(filteredData, 30)
//       : selectedInterval === "year"
//       ? groupByInterval(filteredData, 365)
//       : [filteredData];

//   const summedData = groupedData
//     .map((interval, index) => {
//       if (selectedInterval === "week") {
//         return {
//           interval: `Week ${index + 1}`,
//           ...sumObjectProperties(interval),
//         };
//       } else if (selectedInterval === "month") {
//         return {
//           interval: `Month ${index + 1}`,
//           ...sumObjectProperties(interval),
//         };
//       } else if (selectedInterval === "year") {
//         return {
//           interval: `Year ${index + 1}`,
//           ...sumObjectProperties(interval),
//         };
//       } else {
//         return interval.map((day) => ({ date: day.date, ...day }));
//       }
//     })
//     .flat();

//   let generalTableData = summedData.map((item) => {
//     const { platform, ...rest } = item;
//     return rest;
//   });

//   const calculatePercentageChange = (firstValue, lastValue) => {
//     if (firstValue === 0) return "N/A";
//     const change = ((lastValue - firstValue) / firstValue) * 100;
//     return change.toFixed(2);
//   };

//   const comparisonRow = () => {
//     if (generalTableData.length === 0) return {};

//     const comparisonData = { interval: "Comparison" };

//     const firstData = generalTableData[0];
//     const lastData = generalTableData[generalTableData.length - 1];

//     for (const key in firstData) {
//       if (key !== "interval" && key !== "date") {
//         let firstValue = firstData[key];
//         let lastValue = lastData[key];

//         if (selectedInterval === "daily") {
//           const filteredData = generalTableData.filter((data) => data.date);
//           for (let i = 0; i < filteredData.length; i++) {
//             if (filteredData[i][key] !== 0) {
//               firstValue = filteredData[i][key];
//               break;
//             }
//           }
//           for (let i = filteredData.length - 1; i >= 0; i--) {
//             if (filteredData[i][key] !== 0) {
//               lastValue = filteredData[i][key];
//               break;
//             }
//           }
//         }

//         const percentageChange = calculatePercentageChange(
//           firstValue,
//           lastValue
//         );
//         comparisonData[key] = {
//           value: `${percentageChange}%`,
//           isPositive: percentageChange !== "N/A" && percentageChange >= 0,
//         };
//       }
//     }

//     return comparisonData;
//   };

//   const comparisonData = comparisonRow();
//   generalTableData.push(comparisonData);

//   if (selectedInterval === "daily") {
//     generalTableData = generalTableData.filter((data) => data.date);
//     generalTableData.sort((a, b) => new Date(a.date) - new Date(b.date));
//   }

//   const formatDate = (dateString) => {
//     const [day, month, year] = dateString.split("-");
//     const date = new Date(`${year}-${month}-${day}`);
//     const options = { day: "2-digit", month: "short" };
//     return date.toLocaleDateString("en-GB", options);
//   };

//   const tableDataWithoutComparison = generalTableData.filter(
//     (data) => data.interval !== "Comparison"
//   );

//   return (
//     <section className="tab-container">
//       <Container>
//         <Row>
//           <Col lg={11} className="mx-auto">
//             <Row>
//               <Col lg={12}>
//                 <div className="tab-wrapper">
//                   <div className="tabs-btn-wrapper">
//                     {tabs?.map((tab, index) => (
//                       <li
//                         key={index}
//                         onClick={() => handleTabClick(index)}
//                         className={activeTab === index ? "active" : ""}
//                       >
//                         {tab?.label}
//                       </li>
//                     ))}
//                   </div>

//                   {/* <div className="filter-container form-main-container">
//                     <div className="date-picker-container">
//                       <div className="from-date-picker-wrapper">
//                         <label htmlFor="fromDate">From:</label>
//                         <input
//                           type="date"
//                           id="fromDate"
//                           value={selectedFromDate}
//                           onChange={(e) => {
//                             setSelectedFromDate(e.target.value);
//                           }}
//                         />
//                       </div>

//                       <div className="to-date-picker">
//                         <label htmlFor="toDate">To:</label>
//                         <input
//                           type="date"
//                           id="toDate"
//                           value={selectedToDate}
//                           onChange={(e) => {
//                             setSelectedToDate(e.target.value);
//                           }}
//                         />
//                       </div>
//                     </div>

//                     <div className="sort-filters form-main-container">
//                       <label htmlFor="interval">Choose Interval:</label>
//                       <Dropdown className="cal-form-dropdown interval-selector">
//                         <Dropdown.Toggle
//                           variant="secondary"
//                           id="interval-dropdown"
//                         >
//                           {selectedInterval}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                           <Dropdown.Item
//                             onClick={() => handleIntervalChange("Daily")}
//                           >
//                             Daily
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             onClick={() => handleIntervalChange("Week")}
//                           >
//                             Week
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             onClick={() => handleIntervalChange("Month")}
//                           >
//                             Month
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             onClick={() => handleIntervalChange("Year")}
//                           >
//                             Year
//                           </Dropdown.Item>
//                         </Dropdown.Menu>
//                       </Dropdown>
//                     </div>
//                   </div> */}

//                   {/* <div className="tab-content-container mb-50">
//                     {summedData.length > 0 &&
//                       tabs[activeTab].content({
//                         headersData: updatedHeadersData,
//                         smData: generalTableData,
//                       })}

//                     <div className="kpi-filter-wrapper d-flex align-items-center column-gap-3 form-main-container">
//                       <label htmlFor="kpi">Select KPI:</label>
//                       <Dropdown className="kpi-selector">
//                         <Dropdown.Toggle variant="secondary" id="kpi-dropdown">
//                           {selectedKPI}
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                           {updatedHeadersData?.slice(1).map((kpi, index) => (
//                             <Dropdown.Item
//                               key={index}
//                               onClick={() => handleKPIChange(kpi)}
//                             >
//                               {kpi}
//                             </Dropdown.Item>
//                           ))}
//                         </Dropdown.Menu>
//                       </Dropdown>
//                     </div>

//                     <BarGraphReport
//                       xAxisData={
//                         selectedInterval === "daily"
//                           ? tableDataWithoutComparison.map((data) =>
//                               formatDate(data.date)
//                             )
//                           : tableDataWithoutComparison.map(
//                               (data) => data.interval
//                             )
//                       }
//                       seriesData={tableDataWithoutComparison.map(
//                         (data) => data[selectedKPI]
//                       )}
//                     />
//                   </div> */}
//                 </div>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default TabComponent;




















import { Button, Col, Container, Row, Dropdown } from "react-bootstrap";
import "./TabComponent.css";
import { useEffect, useState } from "react";
import { interval } from "date-fns";
import BarGraphReport from "../bar-graph/BarGraphReport";

const TabComponent = ({ tabs, headersData, smNumbers, handleChangeHeader }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState("daily");
  const [updatedHeadersData, setUpdatedHeadersData] = useState(
    tabs[0].headersData
  );
  const [selectedKPI, setSelectedKPI] = useState(headersData[1]);

  // console.log("activeTab --=>", tabs)

  useEffect(() => {
    handleChangeHeader(activeTab)
  },[activeTab])

  useEffect(() => {
    const headersData = tabs[activeTab].headersData;
    switch (selectedInterval) {
      case "week":
        setUpdatedHeadersData(["Week", ...headersData?.slice(1)]);
        break;
      case "daily":
        setUpdatedHeadersData(["Date", ...headersData?.slice(1)]);
        break;
      case "month":
        setUpdatedHeadersData(["Month", ...headersData?.slice(1)]);
        break;
      case "year":
        setUpdatedHeadersData(["Year", ...headersData?.slice(1)]);
        break;
      default:
        setUpdatedHeadersData(headersData);
    }
  }, [selectedInterval, activeTab, tabs]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleDateRangeSelection = (fromDate, toDate) => {
    setSelectedFromDate(fromDate);
    setSelectedToDate(toDate);
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval.toLowerCase());
  };

  const handleKPIChange = (kpi) => {
    setSelectedKPI(kpi);
  };

  const filteredData = smNumbers
    .filter((data) => {
      const tabLabel = tabs[activeTab].label.toLowerCase();
      return tabLabel.includes(data.platform.toLowerCase());
    })
    .filter((data) => {
      if (selectedFromDate && selectedToDate) {
        const fromDate = new Date(selectedFromDate.replace(/-/g, "/"));
        const toDate = new Date(selectedToDate.replace(/-/g, "/"));
        let dataDate;

        try {
          // Parsing the date string in 'DD-MM-YYYY' format
          const [day, month, year] = data.date.split("-").map(Number);
          dataDate = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date
          if (isNaN(dataDate.getTime())) {
            return false; // Skip invalid date entries
          }
        } catch (error) {
          console.error(`Error parsing date "${data.date}":`, error);
          return false; // Skip invalid date entries
        }


        // Debugging logs to check the values being compared

        // Modify the condition to include both start and end dates
        return dataDate >= fromDate && dataDate <= toDate;
      }
      return true;
    });

  const groupByInterval = (data, days) => {
    const groupedData = [];
    let intervalData = [];
    let dayCounter = 0;

    data.forEach((item, index) => {
      intervalData.push(item);
      dayCounter++;
      if (dayCounter === days || index === data.length - 1) {
        groupedData.push(intervalData);
        intervalData = [];
        dayCounter = 0;
      }
    });

    return groupedData;
  };

  const sumObjectProperties = (objArray) => {
    const sumObj = objArray.reduce((acc, item) => {
      for (const key in item) {
        if (key !== "date" && key !== "platform") {
          if (acc.hasOwnProperty(key)) {
            if (key.includes("Rate") || key.includes("%")) {
              acc[key].sum += item[key];
              acc[key].count += 1;
            } else {
              acc[key] += item[key];
            }
          } else {
            if (key.includes("Rate") || key.includes("%")) {
              acc[key] = { sum: item[key], count: 1 };
            } else {
              acc[key] = item[key];
            }
          }
        }
      }
      return acc;
    }, {});

    for (const key in sumObj) {
      if (typeof sumObj[key] === "object" && sumObj[key].count !== undefined) {
        sumObj[key] = (sumObj[key].sum / sumObj[key].count).toFixed(2);
      }
    }

    return sumObj;
  };

  const groupedData =
    selectedInterval === "week"
      ? groupByInterval(filteredData, 7)
      : selectedInterval === "month"
      ? groupByInterval(filteredData, 30)
      : selectedInterval === "year"
      ? groupByInterval(filteredData, 365)
      : [filteredData];

  const summedData = groupedData
    .map((interval, index) => {
      if (selectedInterval === "week") {
        return {
          interval: `Week ${index + 1}`,
          ...sumObjectProperties(interval),
        };
      } else if (selectedInterval === "month") {
        return {
          interval: `Month ${index + 1}`,
          ...sumObjectProperties(interval),
        };
      } else if (selectedInterval === "year") {
        return {
          interval: `Year ${index + 1}`,
          ...sumObjectProperties(interval),
        };
      } else {
        return interval.map((day) => ({ date: day.date, ...day }));
      }
    })
    .flat();

  let generalTableData = summedData.map((item) => {
    const { platform, ...rest } = item;
    return rest;
  });

  const calculatePercentageChange = (firstValue, lastValue) => {
    if (firstValue === 0) return "N/A";
    const change = ((lastValue - firstValue) / firstValue) * 100;
    return change.toFixed(2);
  };

  const comparisonRow = () => {
    if (generalTableData.length === 0) return {};

    const comparisonData = { interval: "Comparison" };

    const firstData = generalTableData[0];
    const lastData = generalTableData[generalTableData.length - 1];

    for (const key in firstData) {
      if (key !== "interval" && key !== "date") {
        let firstValue = firstData[key];
        let lastValue = lastData[key];

        if (selectedInterval === "daily") {
          const filteredData = generalTableData.filter((data) => data.date);
          for (let i = 0; i < filteredData.length; i++) {
            if (filteredData[i][key] !== 0) {
              firstValue = filteredData[i][key];
              break;
            }
          }
          for (let i = filteredData.length - 1; i >= 0; i--) {
            if (filteredData[i][key] !== 0) {
              lastValue = filteredData[i][key];
              break;
            }
          }
        }

        const percentageChange = calculatePercentageChange(
          firstValue,
          lastValue
        );
        comparisonData[key] = {
          value: `${percentageChange}%`,
          isPositive: percentageChange !== "N/A" && percentageChange >= 0,
        };
      }
    }

    return comparisonData;
  };

  const comparisonData = comparisonRow();
  generalTableData.push(comparisonData);

  if (selectedInterval === "daily") {
    generalTableData = generalTableData.filter((data) => data.date);
    generalTableData.sort((a, b) => new Date(a.date) - new Date(b.date));
  }

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const date = new Date(`${year}-${month}-${day}`);
    const options = { day: "2-digit", month: "short" };
    return date.toLocaleDateString("en-GB", options);
  };

  const tableDataWithoutComparison = generalTableData.filter(
    (data) => data.interval !== "Comparison"
  );

  return (
    <section className="tab-container">
      <Container>
        <Row>
          <Col lg={11} className="mx-auto">
            <Row>
              <Col lg={12}>
                <div className="tab-wrapper">
                  <div className="tabs-btn-wrapper">
                    {tabs.map((tab, index) => (
                      <li
                        key={index}
                        onClick={() => handleTabClick(index)}
                        className={activeTab === index ? "active" : ""}
                      >
                        {tab.label}
                      </li>
                    ))}
                  </div>

                  <div className="filter-container form-main-container">
                    <div className="date-picker-container">
                      <div className="from-date-picker-wrapper">
                        <label htmlFor="fromDate">From:</label>
                        <input
                          type="date"
                          id="fromDate"
                          value={selectedFromDate}
                          onChange={(e) => {
                            setSelectedFromDate(e.target.value);
                          }}
                        />
                      </div>

                      <div className="to-date-picker">
                        <label htmlFor="toDate">To:</label>
                        <input
                          type="date"
                          id="toDate"
                          value={selectedToDate}
                          onChange={(e) => {
                            setSelectedToDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="sort-filters form-main-container">
                      <label htmlFor="interval">Choose Interval:</label>
                      <Dropdown className="cal-form-dropdown interval-selector">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="interval-dropdown"
                        >
                          {selectedInterval}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleIntervalChange("Daily")}
                          >
                            Daily
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleIntervalChange("Week")}
                          >
                            Week
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleIntervalChange("Month")}
                          >
                            Month
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleIntervalChange("Year")}
                          >
                            Year
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="tab-content-container mb-50">
                    {summedData.length > 0 &&
                      tabs[activeTab].content({
                        headersData: updatedHeadersData,
                        smData: generalTableData,
                      })}

                    <div className="kpi-filter-wrapper d-flex align-items-center column-gap-3 form-main-container">
                      <label htmlFor="kpi">Select KPI:</label>
                      <Dropdown className="kpi-selector">
                        <Dropdown.Toggle variant="secondary" id="kpi-dropdown">
                          {selectedKPI}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {updatedHeadersData?.slice(1).map((kpi, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleKPIChange(kpi)}
                            >
                              {kpi}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <BarGraphReport
                      xAxisData={
                        selectedInterval === "daily"
                          ? tableDataWithoutComparison.map((data) =>
                              formatDate(data.date)
                            )
                          : tableDataWithoutComparison.map(
                              (data) => data.interval
                            )
                      }
                      seriesData={tableDataWithoutComparison.map(
                        (data) => data[selectedKPI]
                      )}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TabComponent;