import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../announcement-bar/AnnouncementBar.css";
import { Col, Container, Row } from "react-bootstrap";

const AnnouncementBar = ({pageType, notification}) => {
  return (
    <section className="announcement-bar-wrapper ">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="announcement-txt text-center">
              {
                pageType === "overview" ? (
                  <marquee width="100%" direction="left" height="" className="text-white">
                   {notification?.notification}
                  </marquee>
                ) : (
                  <p className="m-0">
                    Akshara completed her five years at Uniworld today! Let's
                    congratulate her!
                  </p>
                )
              }


            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AnnouncementBar;
