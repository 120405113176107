import React, { useEffect, useState } from "react";
import './AddRolePopup.css'
import CommonButton from "../../../common-components/button/CommonButton";

const AddAndUpdateRolePopup = ({ onClose, onAddRole, roleData, onUpdateRole }) => {
    const [roleName, setRoleName] = useState("");
    const [permissions, setPermissions] = useState([]);
    const [status, setStatus] = useState(roleData?.status || false);

    console.log("status value ==>", status)


    const MODULES = ["employee", "project", "client"];
    const PERMISSION_TYPES = ["add", "edit", "delete"];

    // Utility to transform database format to UI format
    const transformToUIFormat = (permissionsFromDB) => {
        return permissionsFromDB.map((permission) => {
            const [action, module] = permission.split("_");
            return `${module}_${action}`;
        });
    };

    // Utility to transform UI format to database format
    const transformToDBFormat = (permissionsForDB) => {
        return permissionsForDB.map((permission) => {
            const [module, action] = permission.split("_");
            return `${action}_${module}`;
        });
    };

    // Prefill the form if roleData is provided
    useEffect(() => {
        if (roleData) {
            setRoleName(roleData.name || "");
            setPermissions(transformToUIFormat(roleData.permissions || []));
        }
    }, [roleData]);

    const handleCheckboxChange = (module, type) => {
        const permission = `${module}_${type}`;
        setPermissions((prevPermissions) =>
            prevPermissions.includes(permission)
                ? prevPermissions.filter((p) => p !== permission)
                : [...prevPermissions, permission]
        );
    };

    const handleRowSelectAll = (module) => {
        const modulePermissions = PERMISSION_TYPES.map((type) => `${module}_${type}`);
        const isAllSelected = modulePermissions.every((p) => permissions.includes(p));
        setPermissions((prevPermissions) =>
            isAllSelected
                ? prevPermissions.filter((p) => !modulePermissions.includes(p))
                : [...prevPermissions, ...modulePermissions.filter((p) => !prevPermissions.includes(p))]
        );
    };

    const handleColumnSelectAll = (type) => {
        const columnPermissions = MODULES.map((module) => `${module}_${type}`);
        const isAllSelected = columnPermissions.every((p) => permissions.includes(p));
        setPermissions((prevPermissions) =>
            isAllSelected
                ? prevPermissions.filter((p) => !columnPermissions.includes(p))
                : [...prevPermissions, ...columnPermissions.filter((p) => !prevPermissions.includes(p))]
        );
    };

    const handleSelectAllPermissions = () => {
        const allPermissions = MODULES.flatMap((module) =>
            PERMISSION_TYPES.map((type) => `${module}_${type}`)
        );
        const isAllSelected = allPermissions.every((p) => permissions.includes(p));
        setPermissions(isAllSelected ? [] : allPermissions);
    };

    const handleAddRole = (e) => {
        e.preventDefault();
        if (!roleName.trim()) {
            alert("Role name is required!");
            return;
        }

        // Transform permissions to database format before submission
        const formattedPermissions = transformToDBFormat(permissions);
        onAddRole({ name: roleName, permissions: formattedPermissions, status });
    };

    const handleUpdateRole = (e) => {
        console.log("clicking into update role funnction===>")
        e.preventDefault();
        if (!roleName.trim()) {
            alert("Role name is required!");
            return;
        }

        // Transform permissions to database format before submission
        const formattedPermissions = transformToDBFormat(permissions);
        onUpdateRole({ name: roleName, permissions: formattedPermissions, status });
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h2>{roleData ? "Update Role" : "Add New Role"}</h2>
                <div className="form-group role-field-wrapper">
                    <label>Role Name</label>
                    <input
                        type="text"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                        placeholder="Enter role name"
                        required
                    />
                </div>

                <div className="form-group">
                    <table className="permissions-table">
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        <input
                                            type="checkbox"
                                            checked={MODULES.flatMap((module) =>
                                                PERMISSION_TYPES.map((type) => `${module}_${type}`)
                                            ).every((p) => permissions.includes(p))}
                                            onChange={handleSelectAllPermissions}
                                            style={{marginRight : "5px"}}

                                        />
                                        Permissions
                                    </div>
                                </th>
                                {PERMISSION_TYPES.map((type) => (
                                    <th key={type}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={MODULES.every((module) =>
                                                    permissions.includes(`${module}_${type}`)
                                                )}
                                                onChange={() => handleColumnSelectAll(type)}
                                                style={{marginRight : "5px"}}
                                            />
                                            {type.charAt(0).toUpperCase() + type.slice(1)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {MODULES.map((module) => (
                                <tr key={module}>
                                    <td>
                                        <div>
                                            <input
                                                type="checkbox"
                                                checked={PERMISSION_TYPES.every((type) =>
                                                    permissions.includes(`${module}_${type}`)
                                                )}
                                                onChange={() => handleRowSelectAll(module)}
                                                style={{marginRight : "5px"}}
                                            />
                                            {module.charAt(0).toUpperCase() + module.slice(1)}
                                        </div>
                                    </td>
                                    {PERMISSION_TYPES.map((type) => (
                                        <td key={`${module}_${type}`}>
                                            <input
                                                type="checkbox"
                                                checked={permissions.includes(`${module}_${type}`)}
                                                onChange={() => handleCheckboxChange(module, type)}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={(e) => setStatus(e.target.checked)}
 checked ={status} />
  <label class="form-check-label" for="flexSwitchCheckChecked">Status</label>
</div>



                <div className="form-actions">
                    <CommonButton
                        btnName={roleData ? "Update Role" : "Add Role"}
                        onClickAction={roleData ? handleUpdateRole : handleAddRole}
                        btnClass="btn-edit"
                    />
                    <CommonButton
                        btnName="Cancel"
                        btnClass="btn-edit btn-cancel"
                        onClickAction={onClose}
                    />
                </div>
            </div>
        </div>
    );
};


export default AddAndUpdateRolePopup;




// import React, { useEffect, useState } from "react";
// import './AddRolePopup.css'
// import CommonButton from "../../../common-components/button/CommonButton";

// const AddRolePopup = ({ onClose, onAddRole, roleData }) => {
//     const [roleName, setRoleName] = useState("");
//     const [permissions, setPermissions] = useState([]);

//     console.log("roleData coming from update ===>", roleData)

//     const MODULES = ["employee", "project", "client",];
//     const PERMISSION_TYPES = ["add", "edit", "delete"];

//     useEffect(() => {
//         if (roleData) {
//             // Prefill the form fields with the selected role's data
//             setRoleName(roleData.name || "");
//             setPermissions(roleData.permissions || []);
//         }
//     }, [roleData]);
    

//     // Handle individual permission toggle
//     const handleCheckboxChange = (module, type) => {
//         const permission = `${module}_${type}`;
//         setPermissions((prevPermissions) =>
//             prevPermissions.includes(permission)
//                 ? prevPermissions.filter((p) => p !== permission)
//                 : [...prevPermissions, permission]
//         );
//     };

//     // Handle "Select All" for a module (row)
//     const handleRowSelectAll = (module) => {
//         const modulePermissions = PERMISSION_TYPES.map((type) => `${module}_${type}`);
//         const isAllSelected = modulePermissions.every((p) => permissions.includes(p));
//         setPermissions((prevPermissions) =>
//             isAllSelected
//                 ? prevPermissions.filter((p) => !modulePermissions.includes(p))
//                 : [...prevPermissions, ...modulePermissions.filter((p) => !prevPermissions.includes(p))]
//         );
//     };

//     // Handle "Select All" for an action type (column)
//     const handleColumnSelectAll = (type) => {
//         const columnPermissions = MODULES.map((module) => `${module}_${type}`);
//         const isAllSelected = columnPermissions.every((p) => permissions.includes(p));
//         setPermissions((prevPermissions) =>
//             isAllSelected
//                 ? prevPermissions.filter((p) => !columnPermissions.includes(p))
//                 : [...prevPermissions, ...columnPermissions.filter((p) => !prevPermissions.includes(p))]
//         );
//     };

//     // Handle "Select All Permissions"
//     const handleSelectAllPermissions = () => {
//         const allPermissions = MODULES.flatMap((module) =>
//             PERMISSION_TYPES.map((type) => `${module}_${type}`)
//         );
//         const isAllSelected = allPermissions.every((p) => permissions.includes(p));
//         setPermissions(isAllSelected ? [] : allPermissions);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (!roleName.trim()) {
//             alert("Role name is required!");
//             return;
//         }

//         // Transform permissions to the required format
//         const formattedPermissions = permissions?.map((permission) => {
//             const [module, type] = permission?.split("_");
//             return `${type}_${module}`; // Convert to the desired format
//         });

//         onAddRole({ name: roleName, permissions: formattedPermissions });
//     };


//     return (
//         <div className="popup-overlay">
//             <div className="popup-content">
//                 <h2>Add New Role</h2>
//                 {/* <form onSubmit={handleSubmit}> */}
//                 {/* Role Name Input */}
//                 <div className="form-group">
//                     <label>Role Name</label>
//                     <input
//                         type="text"
//                         value={roleName}
//                         onChange={(e) => setRoleName(e.target.value)}
//                         placeholder="Enter role name"
//                         required
//                     />
//                 </div>

//                 {/* Permissions Table */}
//                 <div className="form-group">
//                     {/* <label>Permissions</label> */}
//                     <table className="permissions-table">
//                         <thead>
//                             <tr>
//                                 <th>
//                                     <div>
//                                         <input
//                                             type="checkbox"
//                                             checked={MODULES.flatMap((module) =>
//                                                 PERMISSION_TYPES.map((type) => `${module}_${type}`)
//                                             ).every((p) => permissions.includes(p))}
//                                             onChange={handleSelectAllPermissions}
//                                         />
//                                         Permissions
//                                     </div>
//                                 </th>
//                                 {PERMISSION_TYPES.map((type) => (
//                                     <th key={type}>
//                                         <div>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={MODULES.every((module) =>
//                                                     permissions.includes(`${module}_${type}`)
//                                                 )}
//                                                 onChange={() => handleColumnSelectAll(type)}
//                                             />
//                                             {type.charAt(0).toUpperCase() + type.slice(1)}
//                                         </div>
//                                     </th>
//                                 ))}
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {MODULES.map((module) => (
//                                 <tr key={module}>
//                                     <td>
//                                         <div>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={PERMISSION_TYPES.every((type) =>
//                                                     permissions.includes(`${module}_${type}`)
//                                                 )}
//                                                 onChange={() => handleRowSelectAll(module)}
//                                             />
//                                             {module.charAt(0).toUpperCase() + module.slice(1)}
//                                         </div>
//                                     </td>
//                                     {PERMISSION_TYPES.map((type) => (
//                                         <td key={`${module}_${type}`}>
//                                             <input
//                                                 type="checkbox"
//                                                 checked={permissions.includes(`${module}_${type}`)}
//                                                 onChange={() => handleCheckboxChange(module, type)}
//                                             />
//                                         </td>
//                                     ))}
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>

//                 {/* Submit and Close Buttons */}
//                 <div className="form-actions">
//                     <CommonButton btnName="Add Role" btnClass="btn-edit" onClickAction={handleSubmit} />
//                     <CommonButton btnName="Cancel" btnClass="btn-edit btn-cancel" onClickAction={onClose} />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AddRolePopup;
